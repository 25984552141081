import { Header } from '@components/ui/header'
import { Advantages, Advantages2, Start } from '@components/ui/sections'

// import { Feedbacks } from '@components/ui/sections/feedbacks'

// import { Plans } from '@components/ui/sections/plans'

export function MainRoute() {
  return (
    <div className="flex flex-col">
      <Header></Header>
      <Start></Start>
      <Advantages></Advantages>
      <Advantages2></Advantages2>
      {/* <Feedbacks></Feedbacks> */}
      {/* <Plans></Plans> */}
    </div>
  )
}
