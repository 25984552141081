import { BaseSyntheticEvent, useRef, useState } from 'react'
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { Button } from '@components/ui/button'
import { Card } from '@components/ui/card'
import { Container } from '@components/ui/container'
import { Icon } from '@components/ui/icon'
import { MangoName } from '@components/ui/icon/mango-name'
import { InputCheckboxController } from '@components/ui/inputs/controller-inputs/input-checkbox-controller'
import { InputPhoneCellPhoneController } from '@components/ui/inputs/controller-inputs/input-phone-cellphone-controller'
import { InputTextController } from '@components/ui/inputs/controller-inputs/input-text-controller'
import { getErrorMessage } from '@utils/error/get-error-message'
import { cn } from '@utils/styles'
import { env } from '@config/env'

export class RequestError extends Error {
  readonly status: number

  constructor(status: number, message: string) {
    super(message)
    this.status = status
  }
}

const formSchema = z.object({
  agree: z.literal<boolean>(true, {
    errorMap: () => ({
      message:
        'Você precisa concordar com os Termos de Uso e Política de Privacidade para prosseguir com o cadastro',
    }),
  }),
  email: z
    .string({ required_error: 'Esse campo é obrigatório' })
    .min(1, { message: 'Esse campo é obrigatório' })
    .max(50, { message: 'E-mail inválido' })
    .email({ message: 'E-mail inválido' })
    .trim()
    .toLowerCase(),
  fullName: z
    .string({ required_error: 'Esse campo é obrigatório' })
    .min(1, { message: 'Esse campo é obrigatório' })
    .max(50)
    .trim()
    .refine(
      (fullName) =>
        fullName?.split(' ')?.length >= 2 &&
        !fullName?.split(' ').some((x) => x.length < 2),
      {
        message: 'Digite o nome completo',
      }
    ),
  phone: z
    .string({ required_error: 'Esse campo é obrigatório' })
    .length(16, { message: 'Número inválido' })
    .or(z.string().length(14, { message: 'Número inválido' }).trim()),
  recaptcha_token: z.string(),
  isLoading: z.boolean().optional(),
})
export type FormSchema = z.infer<typeof formSchema>

export function UserRegisterRoute() {
  const [isToVerifyEmail, setToVerifyEmail] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const toast = useToast()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const {
    setValue,
    watch,
    reset,
    control,
    handleSubmit: submit,
    formState: { isSubmitting },
  } = useForm<FormSchema>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      agree: false,
      fullName: '',
      email: '',
      isLoading: false,
      phone: '',
      recaptcha_token: '',
    },
  })
  const registerMutation = useMutation(
    async (form: FormSchema) => {
      const path = '/users'
      const response = await fetch(`${env.apiUrl}${path}`, {
        method: 'POST',
        body: JSON.stringify(form),
      })

      if (!response.ok) {
        const errorMessage = await response.text()
        throw new RequestError(response.status, errorMessage)
      }

      const data = await response.json()
      return data as FormSchema
    },
    {
      onMutate: (data) => {
        setEmail(data.email)
      },
      onSuccess: () => {
        setToVerifyEmail(true)
        reset({
          agree: false,
          fullName: '',
          email: '',
          isLoading: false,
          phone: '',
          recaptcha_token: '',
        })
      },
      onError: (error) => {
        recaptchaRef?.current?.reset()
        toast({
          title: 'Falha no cadastro',
          description: getErrorMessage(error),
          status: 'warning',
          duration: 6000,
          isClosable: true,
        })
      },
    }
  )

  const handleSubmit = async (
    formData: FormSchema,
    e: BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    setValue('isLoading', true)
    e?.preventDefault()
    const token = await recaptchaRef?.current?.executeAsync()
    if (!!token) {
      setValue('isLoading', false)
      setValue('recaptcha_token', token)
      formData.recaptcha_token = token
      delete formData.isLoading
      registerMutation.mutate(formData)
    } else {
      recaptchaRef?.current?.reset()
      setValue('isLoading', false)
      toast({
        title: 'Falha no cadastro',
        description: 'Por favor, tente novamente',
        status: 'warning',
        duration: 6000,
        isClosable: true,
      })
    }
  }

  const Verify = () => {
    return (
      <Container
        id="verify"
        className={cn(
          'flex h-full max-w-full items-center justify-center bg-neutral-n11 p-0 max-md:flex-col sm:p-0 md:p-0 lg:p-0'
        )}
      >
        <Card
          className={cn(
            'm-6 flex flex-col items-center justify-center gap-8 bg-neutral-n12'
          )}
        >
          <div className="flex flex-col items-center">
            <Icon name="mail" color="primary-p-0" size="60" />
            <span className="text-[40px] font-bold text-primary-p-0">
              Verifique o seu email
            </span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-2 font-medium text-neutral-n0">
              Realize a confirmação através do email enviado para
            </span>
            <span className="text-2 font-bold text-neutral-n0">{email}</span>
          </div>
          <span className="text-base font-medium text-neutral-n0">
            Certifique-se de verificar a sua caixa de spam caso não encontre o
            email em sua caixa de email.
          </span>
          <MangoName
            id="icon-button-mango-name-01"
            className="class-id-icon-button-mango-name cursor-pointer"
            width={106}
            height={32}
            onClick={() => navigate({ pathname: '/' })}
          />
        </Card>
      </Container>
    )
  }

  // const handleError: SubmitErrorHandler<FormSchema> = (errors) => {
  //   console.log('ERRORS:', errors)
  // }

  return (
    <div className="flex h-full flex-col">
      {!!isToVerifyEmail ? (
        <Verify />
      ) : (
        <ChakraProvider>
          <Container
            id="register"
            className="flex h-full p-0 max-md:flex-col sm:p-0 md:p-0 lg:p-0"
          >
            <div className="flex h-full flex-1 flex-col items-center justify-center gap-12 bg-neutral-n11 px-0 max-md:hidden">
              <MangoName
                className="class-id-icon-button-mango-name h-[100dvh] max-h-[48px] min-h-[12px] min-w-[40x] max-w-[159px] cursor-pointer"
                width={159}
                height={48}
                onClick={() => navigate({ pathname: '/' })}
              />
            </div>
            <form
              className="flex h-full flex-1 flex-col justify-center gap-6 overflow-auto p-4 lg:px-10 lg:pt-10"
              id="form"
              onSubmit={submit(handleSubmit)}
            >
              <div className="flex flex-row justify-center text-center md:hidden">
                <MangoName
                  id="icon-button-mango-name-02"
                  className="class-id-icon-button-mango-name cursor-pointer"
                  width={106}
                  height={32}
                  onClick={() => navigate({ pathname: '/' })}
                />
              </div>
              <div className="flex flex-col justify-center text-center">
                <span className="text-4xl font-bold text-primary-p-d90 max-lg:text-3xl max-sm:text-2xl">
                  Realize o seu{' '}
                  <span className="text-4xl font-bold text-primary-p-0 max-lg:text-3xl max-sm:text-2xl">
                    Cadastro
                  </span>
                </span>
              </div>
              <div className="flex flex-col gap-3 ">
                <InputTextController<FormSchema>
                  control={control}
                  maxLength={50}
                  label="Nome Completo"
                  name="fullName"
                  placeholder="Insira seu nome completo"
                />
                <InputTextController<FormSchema>
                  control={control}
                  maxLength={50}
                  label="E-mail"
                  name="email"
                  placeholder="Insira seu e-mail"
                />
                <InputPhoneCellPhoneController<FormSchema>
                  control={control}
                  label="Telefone"
                  name="phone"
                  placeholder="(00) 0 0000 0000"
                />
              </div>

              <div className="flex flex-row gap-8">
                <InputCheckboxController<FormSchema>
                  control={control}
                  name="agree"
                />
                <div className="flex">
                  <span className="text-base font-medium text-primary-p-0 max-lg:text-sm">
                    Declaro que li e concordo integralmente com os{' '}
                    <a
                      className="font-bold"
                      href="https://politicaprivacidade.mango.app.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Termos de uso
                    </a>{' '}
                    e{' '}
                    <a
                      className="font-bold"
                      href="https://politicaprivacidade.mango.app.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de privacidade
                    </a>
                  </span>
                </div>
              </div>

              <Button
                id="button-submit"
                renderSlot1={
                  (!!registerMutation?.isLoading || !!watch('isLoading')) && (
                    <Icon
                      className="animate-spin"
                      name="spinner"
                      color="neutral-n12"
                      size="24"
                    />
                  )
                }
                className="mt-4 min-h-[40px]"
                type="submit"
                text="Cadastrar agora"
                intent="primary"
                disabled={
                  isSubmitting ||
                  !!registerMutation?.isLoading ||
                  !!watch('isLoading')
                }
              />
              <ReCAPTCHA
                size="invisible"
                sitekey={env.google.recaptcha!}
                ref={recaptchaRef}
              />
            </form>
          </Container>
        </ChakraProvider>
      )}
    </div>
  )
}

export const UserRegisterButton = ({
  id,
  className,
}: {
  id?: string
  className?: string
}) => {
  const navigate = useNavigate()
  return (
    <Button
      id={id}
      onClick={() => navigate({ pathname: '/user-register' })}
      type="button"
      text="Cadastre-se de graça"
      className={cn('max-sm:w-full', className)}
      intent="primary"
    />
  )
}
