import { Button, type ButtonProps } from '@components/ui/button'
import { type IconName } from '@components/ui/icon'

type IconButtonProps = Omit<
  ButtonProps,
  | 'iconLeft'
  | 'iconRight'
  | 'renderSlot1'
  | 'renderSlot2'
  | 'renderSlot3'
  | 'renderSlot4'
  | 'text'
> & {
  iconName: IconName
}

export const IconButton = ({ iconName, ...otherProps }: IconButtonProps) => {
  return <Button iconLeft={iconName} {...otherProps} />
}
