export function validateCNPJ(cnpj: string): boolean {
  // Remove non-numeric characters
  const cleanCNPJ = cnpj.replace(/\D/g, '')

  // Check if CNPJ has 14 digits
  if (cleanCNPJ.length !== 14) {
    return false
  }

  // Validate CNPJ algorithm
  let sum = 0
  let size = 5
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cleanCNPJ.charAt(i)) * size
    size = size === 2 ? 9 : size - 1
  }

  const expectedDigit1 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (parseInt(cleanCNPJ.charAt(12)) !== expectedDigit1) {
    return false
  }

  sum = 0
  size = 6
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cleanCNPJ.charAt(i)) * size
    size = size === 2 ? 9 : size - 1
  }

  const expectedDigit2 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  return parseInt(cleanCNPJ.charAt(13)) === expectedDigit2
}
