import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  type MouseEvent,
  useState,
} from 'react'
import { cn } from '@/utils/styles'
import { cva, type VariantProps } from 'cva'
import { castColor, type Color } from '@config/colors'
import { Icon, type IconName } from './icon'

export const buttonVariants = cva(
  [
    'rounded-lg',
    'font-bold',
    'flex',
    'gap-2',
    'items-center',
    'justify-center',
    'select-none',
    'cursor-pointer',
  ],
  {
    variants: {
      active: {
        true: '',
      },
      disabled: {
        true: '!cursor-not-allowed',
      },
      intent: {
        primary: 'text-neutral-n12',
        secondary: 'border-2 border-primary-p-l80',
        tertiary: '',
      },
      size: {
        medium: 'h-10 px-2',
        large: 'h-14 px-4',
      },
      state: {
        rest: '',
        hover: '',
      },
    },
    compoundVariants: [
      // BGs
      {
        intent: 'primary',
        disabled: false,
        state: 'rest',
        class: 'bg-primary-p-0',
      },
      {
        intent: 'primary',
        disabled: false,
        state: 'hover',
        class: 'bg-primary-p-l40',
      },
      {
        intent: 'primary',
        disabled: true,
        state: ['rest', 'hover'],
        class: 'bg-neutral-n8',
      },
      {
        intent: 'secondary',
        active: false,
        state: ['rest', 'hover'],
        class: 'bg-neutral-n12',
      },
      {
        intent: 'secondary',
        active: true,
        class: 'bg-neutral-n10',
      },
      {
        intent: 'secondary',
        disabled: true,
        class: 'bg-neutral-n12',
      },
      // shadows
      {
        intent: ['primary', 'secondary'],
        disabled: false,
        state: ['hover'],
        class: 'shadow-button',
      },
      // text color
      {
        intent: 'secondary',
        disabled: false,
        class: 'text-primary-p-0',
      },
      {
        intent: 'secondary',
        disabled: true,
        state: ['rest', 'hover'],
        class: 'text-neutral-n8',
      },
      {
        intent: ['secondary', 'tertiary'],
        disabled: false,
        state: 'hover',
        class: 'text-primary-p-l40',
      },
      {
        intent: 'tertiary',
        disabled: false,
        state: 'rest',
        class: 'text-primary-p-0',
      },
      {
        intent: 'tertiary',
        disabled: true,
        state: ['rest', 'hover'],
        class: 'text-neutral-n10',
      },
    ],
    defaultVariants: {
      active: false,
      disabled: false,
      intent: 'secondary',
      size: 'medium',
      state: 'rest',
    },
  }
)

export const iconColorVariants = cva('', {
  variants: {
    active: {
      true: '',
    },
    disabled: {
      true: '',
    },
    intent: {
      primary: castColor('neutral-n12'),
      secondary: '',
      tertiary: '',
    },
    state: {
      rest: '',
      hover: '',
    },
  },
  compoundVariants: [
    {
      intent: 'secondary',
      active: true,
      class: castColor('primary-p-0'),
    },
    {
      intent: 'secondary',
      disabled: true,
      class: castColor('neutral-n8'),
    },
    {
      intent: 'tertiary',
      disabled: true,
      class: castColor('neutral-n10'),
    },
    {
      intent: ['secondary', 'tertiary'],
      state: ['rest'],
      class: castColor('primary-p-0'),
    },
    {
      intent: ['secondary', 'tertiary'],
      state: 'hover',
      class: castColor('primary-p-l40'),
    },
  ],
  defaultVariants: {
    active: false,
    disabled: false,
    intent: 'secondary',
    state: 'rest',
  },
})

type ButtonProps = Omit<ComponentPropsWithoutRef<'button'>, 'children'> &
  Omit<VariantProps<typeof buttonVariants>, 'state'> & {
    active?: boolean
    iconColor?: Color
    iconLeft?: IconName
    iconRight?: IconName
    iconSize?: number
    renderSlot1?: ReactNode
    renderSlot2?: ReactNode
    renderSlot3?: ReactNode
    renderSlot4?: ReactNode
    text?: string
  }

const Button = ({
  active = false,
  className = '',
  disabled = false,
  intent = 'secondary',
  iconColor: iconColorProp = undefined,
  iconLeft = undefined,
  iconRight = undefined,
  iconSize = 24,
  renderSlot1 = null,
  renderSlot2 = null,
  renderSlot3 = null,
  renderSlot4 = null,
  size = 'medium',
  text = '',
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  ...otherProps
}: ButtonProps) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const handleMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    onMouseEnter(e)
    setHovered(() => true)
  }
  const handleMouseLeave = (e: MouseEvent<HTMLButtonElement>) => {
    onMouseLeave(e)
    setHovered(() => false)
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    onClick(e)
  }

  const state = hovered ? 'hover' : 'rest'

  const iconColor = iconColorVariants({
    active,
    disabled,
    intent,
    state,
  }) as Color
  return (
    <button
      className={cn(
        buttonVariants({
          active,
          disabled,
          intent,
          size,
          state,
        }),
        className
      )}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...otherProps}
    >
      {renderSlot1}
      {!!iconLeft && <Icon name={iconLeft} color={iconColor} size={iconSize} />}
      {renderSlot2}
      {!!text && <span>{text}</span>}
      {renderSlot3}
      {!!iconRight && (
        <Icon name={iconRight} color={iconColor} size={iconSize} />
      )}
      {renderSlot4}
    </button>
  )
}

Button.displayName = 'Button'

export { type ButtonProps, Button }
