import { ComponentPropsWithRef, useState } from 'react'
import { cva } from 'cva'
import { cx } from 'cva'
import { RegisterButton } from '@components/routes/register-route'
import { Button } from '@components/ui/button'
import { Icon } from '@components/ui/icon'
import { IconButton } from '@components/ui/icon-button'
import { MangoName } from '@components/ui/icon/mango-name'
import { LabelText } from '@components/ui/inputs/base-inputs/support-components/label-text'
import { scrollTo } from '@utils/scroll/scroll-to'
import { env } from '@config/env'

type GoToProps = ComponentPropsWithRef<'div'> & {
  text: string
  goto: string
}
export function GoTo({
  id = '',
  text = '',
  goto = '',
  className = '',
}: GoToProps) {
  return (
    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 transform max-md:hidden">
      <div
        id={id}
        className={cx(
          'flex flex-row gap-2 rounded-t-lg bg-neutral-n11 p-4',
          className
        )}
        onClick={() => scrollTo(goto)}
      >
        <span className="text-xl font-bold text-primary-p-0">{text}</span>
        <Icon name="circle-arrow-down" color="neutral-n6" />
      </div>
    </div>
  )
}

type Props = ComponentPropsWithRef<'div'>

export function Header() {
  const [visible, setVisibility] = useState(false)

  const Labels = ({ className = '' }: Props) => {
    const handleClick = (id: string) => {
      scrollTo(id)
      setVisibility(false)
    }

    return (
      <div className={cx('flex items-center gap-8', className)}>
        <LabelText
          id="button-label-advantages"
          className="class-id-button-label hidden self-center !text-lg font-medium"
          onClick={() => handleClick('advantages')}
        >
          Vantagens
        </LabelText>
        <LabelText
          id="button-label-feedbacks"
          className="class-id-button-label hidden self-center !text-lg font-medium"
          onClick={() => handleClick('feedbacks')}
        >
          Depoimentos
        </LabelText>
        <LabelText
          id="button-label-plans"
          className="class-id-button-label hidden self-center !text-lg font-medium"
          onClick={() => handleClick('plans')}
        >
          Planos
        </LabelText>
      </div>
    )
  }

  const Buttons = ({ className = '' }: Props) => {
    return (
      <div
        className={cx(
          'flex items-center gap-4 max-md:flex-col max-md:gap-8',
          className
        )}
      >
        <Button
          type="button"
          text="Ver Demonstração"
          id="button-see-demonstration"
          className="max-sm:w-full"
          onClick={() => window.open('https://teste.mango.app.br')}
        />

        <Button
          type="button"
          text="Entrar"
          className="max-sm:w-full"
          id="button-entrer"
          onClick={() => window.open(env.managerUrl, '_self')}
        />
        <RegisterButton
          id="button-register-01"
          className="class-id-button-register"
        />
      </div>
    )
  }

  const Menu = () => {
    return (
      <div className="md:hidden">
        <IconButton
          id="button-icon-menu"
          iconName="menu2"
          intent="tertiary"
          onClick={() => {
            setVisibility(true)
          }}
        />
      </div>
    )
  }

  const Drawer = () => {
    const drawerVariants = cva(
      // 'fixed right-0 top-0 h-full z-20 w-60 md:w-72 translate-x-full transform bg-neutral-n12 p-4 shadow-lg transition-all duration-500',
      'fixed right-0 top-0 h-full z-20 w-60 md:w-72 bg-neutral-n12 p-4 shadow-lg',
      {
        variants: {
          open: {
            // true: 'translate-x-0',
            true: 'visible',
            false: 'invisible',
          },
        },
        defaultVariants: {
          open: false,
        },
      }
    )
    return (
      <div className="flex md:hidden">
        {visible && (
          <div
            className="fixed left-0 top-0 z-1 h-full w-full bg-neutral-n12 bg-opacity-50"
            onClick={() => {
              setVisibility(false)
            }}
          ></div>
        )}
        <div className={drawerVariants({ open: visible })}>
          <IconButton
            id="button-icon-close-drawer"
            iconName="close"
            intent="tertiary"
            onClick={() => {
              setVisibility(false)
            }}
          />
          <div className="flex flex-col items-center gap-8">
            <Labels className="flex-col" />
            <Buttons />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mx-auto flex h-[72px] w-full max-w-[1440px] flex-row items-center justify-between px-4 font-sans sm:px-14 lg:px-28">
        <MangoName width={106} height={32} />
        <Labels className="max-md:hidden" />
        <Buttons className="max-md:hidden" />
        <Menu />
      </div>
      <Drawer />
    </>
  )
}
