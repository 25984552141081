import { Controller, type FieldValues } from 'react-hook-form'
import {
  InputPhoneCellPhone,
  type InputPhoneCellPhoneProps,
} from '@components/ui/inputs/base-inputs/input-phone-cellphone'
import type { BaseControllerProps } from './types/base-controller-props'

export const InputPhoneCellPhoneController = <TFormSchema extends FieldValues>({
  control,
  name,
  ...externalProps
}: BaseControllerProps<InputPhoneCellPhoneProps, TFormSchema>) => {
  return (
    <Controller
      control={control}
      name={name as string}
      render={({ field: controllerProps, fieldState }) => {
        return (
          <InputPhoneCellPhone
            {...{ ...externalProps, ...controllerProps }}
            errorMessage={fieldState.error?.message}
          />
        )
      }}
    />
  )
}
