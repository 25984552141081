import type { FC } from 'react'
// import mixpanel from 'mixpanel-browser';
import { QueryClientProvider } from 'react-query'
import { Routes } from '@components/routes/routes'
import { client } from '@config/react-query'

// mixpanel.init('65598926e6ef5fa966695b99ed58d98d') //, { debug: true })
// mixpanel.identify()
// mixpanel.track('Entrou no Mango')

export const App: FC = () => (
  <QueryClientProvider client={client}>
    <Routes />
  </QueryClientProvider>
)
