import { type ComponentPropsWithoutRef } from 'react'

export const InlineWrapper = ({
  children = null,
  ...otherProps
}: ComponentPropsWithoutRef<'div'>) => (
  <div className="flex cursor-pointer items-center gap-2" {...otherProps}>
    {children}
  </div>
)
