import type { HTMLProps } from 'react'

type ShrimpProps = HTMLProps<SVGSVGElement>

export function Shrimp({
  color = '#000',
  width = 24,
  height = 24,
  size = 0,
  ...otherProps
}: ShrimpProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={color}
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M88 48C88 34.7452 77.2548 24 64 24V16C81.6731 16 96 30.3269 96 48C96 65.6731 81.6731 80 64 80V72C77.2548 72 88 61.2548 88 48Z" />
      <path d="M64 64C64 59.5817 60.4183 56 56 56V48C64.8366 48 72 55.1634 72 64C72 72.8366 64.8366 80 56 80V72C60.4183 72 64 68.4183 64 64Z" />
      <path d="M44 60C44 57.7909 45.7909 56 48 56C59.0457 56 68 64.9543 68 76C68 87.0457 59.0457 96 48 96C45.7909 96 44 94.2091 44 92C44 89.7909 45.7909 88 48 88C54.6274 88 60 82.6274 60 76C60 69.3726 54.6274 64 48 64C45.7909 64 44 62.2091 44 60Z" />
      <path d="M64 80C57.3726 80 52 85.3726 52 92C52 94.2091 50.2091 96 48 96C45.7909 96 44 94.2091 44 92C44 80.9543 52.9543 72 64 72C66.2091 72 68 73.7909 68 76C68 78.2091 66.2091 80 64 80Z" />
      <path d="M48 56C50.2091 56 52 57.7909 52 60C52 66.6274 57.3726 72 64 72C66.2091 72 68 73.7909 68 76C68 78.2091 66.2091 80 64 80C52.9543 80 44 71.0457 44 60C44 57.7909 45.7909 56 48 56Z" />
      <path d="M12 20C12 35.464 24.536 48 40 48V56C20.1177 56 4 39.8823 4 20H12Z" />
      <path d="M36 52C36 49.7909 37.7909 48 40 48L64 48C66.2091 48 68 49.7909 68 52C68 54.2091 66.2091 56 64 56L40 56C37.7909 56 36 54.2091 36 52Z" />
      <path d="M8 20C8 17.7909 9.79086 16 12 16H64C66.2091 16 68 17.7909 68 20C68 22.2091 66.2091 24 64 24H12C9.79086 24 8 22.2091 8 20Z" />
      <path d="M32 32C32 34.2091 30.2091 36 28 36C25.7909 36 24 34.2091 24 32C24 29.7909 25.7909 28 28 28C30.2091 28 32 29.7909 32 32Z" />
      <path d="M24 8C17.3726 8 12 13.3726 12 20H4C4 8.95431 12.9543 0 24 0V8Z" />
      <path d="M20 4C20 1.79086 21.7909 0 24 0H64C66.2091 0 68 1.79086 68 4C68 6.20914 66.2091 8 64 8H24C21.7909 8 20 6.20914 20 4Z" />
      <path d="M52 36C52 29.3726 46.6274 24 40 24V16C51.0457 16 60 24.9543 60 36C60 47.0457 51.0457 56 40 56V48C46.6274 48 52 42.6274 52 36Z" />
      <path d="M60 20C60 17.7909 61.7909 16 64 16C75.0457 16 84 24.9543 84 36C84 47.0457 75.0457 56 64 56C61.7909 56 60 54.2091 60 52C60 49.7909 61.7909 48 64 48C70.6274 48 76 42.6274 76 36C76 29.3726 70.6274 24 64 24C61.7909 24 60 22.2091 60 20Z" />
      <path d="M68 50C68 55.5228 72.4771 60 78 60C83.5229 60 88 55.5228 88 50H96C96 59.9411 87.9411 68 78 68C68.0589 68 60 59.9411 60 50H68Z" />
      <path d="M0 36C0 33.7909 1.79086 32 4 32H12C14.2091 32 16 33.7909 16 36C16 38.2091 14.2091 40 12 40H4C1.79086 40 0 38.2091 0 36Z" />
    </svg>
  )
}
