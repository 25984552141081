import type { ComponentPropsWithoutRef } from 'react'
import { cva, cx } from 'cva'

const variants = cva(
  ['text-sm', 'font-bold', 'leading-4', 'cursor-pointer', 'self-start'],
  {
    variants: {
      invalid: {
        true: 'text-semantic-error',
        false: 'text-neutral-n4',
      },
    },
    defaultVariants: {
      invalid: false,
    },
  }
)

type LabelTextProps = ComponentPropsWithoutRef<'span'> & {
  invalid?: boolean
}

export const LabelText = ({
  className = '',
  children = null,
  invalid = false,
  onClick = () => {},
}: LabelTextProps) => (
  <span onClick={onClick} className={cx(variants({ invalid }), className)}>
    {children}
  </span>
)
