import { cx } from 'cva'
import { RegisterButton } from '@components/routes/register-route'
import { Card } from '@components/ui/card'
import { Container } from '@components/ui/container'
import { Image } from '@components/ui/img'

export function Advantages() {
  return (
    <div className="flex w-full bg-neutral-n11">
      <Container id="advantages" className="flex-col gap-12">
        <div className="flex flex-col gap-2 text-center">
          <span className="text-4xl font-bold text-primary-p-d90 max-sm:text-2xl">
            Como o Mango pode ajudar{' '}
            <span className="text-4xl font-bold text-primary-p-0 max-sm:text-2xl">
              o seu negócio
            </span>
          </span>
          <span className="text-lg font-medium text-primary-p-d90 max-sm:text-base">
            Com o Mango, você potencializa e conquista os clientes mais
            exigentes.
          </span>
        </div>
        <div className="flex w-full gap-12 max-md:flex-col max-md:gap-4">
          <div className="flex flex-1 flex-col gap-4">
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="pig-money"
              title="Economize com impressões"
              subtitle="Reduza custos com cardápios físicos elaborados. Com o Mango você pode modificar o seu cardápio e imprimi-lo a qualquer momento."
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="chart-histogram"
              title="Acompanhe a oscilação de preços do mercado"
              subtitle="Com o Mango você tem a liberdade para fazer reajuste de preço nos seus produtos a qualquer momento e de forma rápida e independente!"
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="file-smile"
              title="Customize com praticidade"
              subtitle="Deseja adicionar ou remover itens do cardápio, atualizar a imagem de um produto, ou tornar indisponíveis produtos temporariamente? Com o Mango você pode!"
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="gavel"
              title="Esteja aderente às regulamentações"
              subtitle="Com o Mango você pode ficar tranquilo, nossa aplicação fornece todas as funcionalidades necessárias para que você esteja em conformidade com as leis federais, estaduais e municipais."
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
          </div>
          {/* <div className="flex flex-1 flex-col items-center justify-center">
            <Image
              src="https://res.cloudinary.com/ds7oehzox/image/upload/landingpage/xadxiseezyfdieiy5oi6.png"
              className="h-full min-h-[313px] w-full min-w-[313px]"
              alt={'Mango Manager'}
            />
          </div> */}
        </div>

        <div className="flex flex-1 flex-row items-center justify-center">
          <RegisterButton
            id="button-register-02"
            className="class-id-button-register"
          />
        </div>
      </Container>
    </div>
  )
}

export function Advantages2() {
  return (
    <div className="flex w-full bg-neutral-n11">
      <Container id="advantages2" className="relative flex-col gap-12">
        <div className="flex flex-col gap-2 text-center">
          <span className="text-4xl font-bold text-primary-p-d90 max-sm:text-2xl">
            Como o Mango pode ajudar{' '}
            <span className="text-4xl font-bold text-primary-p-0 max-sm:text-2xl">
              os seus clientes
            </span>
          </span>
          <span className="text-lg font-medium text-primary-p-d90 max-sm:text-base">
            Com o Mango, seus clientes terão acesso a uma experiência sob
            medida.
          </span>
        </div>
        <div className="flex w-full gap-12 max-md:flex-col">
          <div className="flex flex-1 flex-col items-center justify-center">
            <Image
              src="https://res.cloudinary.com/ds7oehzox/image/upload/landingpage/ngke6zduwsaddmg1hzys.png"
              className="h-full max-h-[658px] min-h-[313px] w-full min-w-[313px]"
              alt={'Menu Mango'}
            />
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="mood-smile-beam"
              title="Proporcione experiências incríveis"
              subtitle="O Mango conta com um time de especialistas em experiência do usuário focados em garantir que os usuários realmente gostem de utilizar o seu cardápio."
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="milk-off"
              title="Sinalize facilmente as restrições alimentares"
              subtitle="Com o filtro de restrições alimentares, seus clientes podem consultar, dentre as opções do restaurante, o que pode ser consumido de acordo com as suas restrições alimentares."
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
            <Card
              iconColorLeft="neutral-n6"
              iconNameLeft="plant"
              title="Contribua com o meio ambiente"
              subtitle="Ao utilizar o cardápio digital do Mango você contribui com o meio ambiente reduzindo o consumo de plástico e papel. O futuro começa agora."
              classNameTitle={cx(
                'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
              )}
              classNameSubtitle={cx(
                'text-primary-p-d90 text-lg !font-medium max-sm:text-sm'
              )}
            ></Card>
            <RegisterButton
              id="button-register-03"
              className="class-id-button-register"
            />
          </div>
        </div>
        {/* <GoTo text="Depoimentos" goto="feedbacks" id='button-goto-01' className="bg-neutral-n12 class-id-button-goto" /> */}
      </Container>
    </div>
  )
}
