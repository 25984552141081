export function getErrorMessage(error: any): string {
  if (
    typeof error?.message === 'string' &&
    error.message.includes('"message"')
  ) {
    try {
      const parsedJson = JSON.parse(error.message)
      return parsedJson?.message || parsedJson?.errors?.[0]?.message || ''
    } catch (error) {
      console.error(error)

      if (error instanceof SyntaxError) {
        return error.message
      }
    }
  }
  return error?.message || error?.errors?.[0]?.message || ''
}
