import type { ComponentPropsWithoutRef } from 'react'
import { cx } from 'cva'

export const Container = ({
  className,
  children,
  ...otherProps
}: ComponentPropsWithoutRef<'div'>) => (
  <div className={cx('flex flex-col gap-1', className)} {...otherProps}>
    {children}
  </div>
)
