// TODO merge this with tailwind config file?
export const colors = {
  'primary-p-l100': '#ffffff',
  'primary-p-l90': '#f1f3f0',
  'primary-p-l80': '#e3e6e2',
  'primary-p-l60': '#c6cdc5',
  'primary-p-l40': '#aab5a7',
  'primary-p-0': '#71836d',
  'primary-p-d20': '#5a6957',
  'primary-p-d60': '#2d342c',
  'primary-p-d90': '#0b0d0b',
  'primary-p-d100': '#000000',
  'neutral-n0': '#000000',
  'neutral-n1': '#070807',
  'neutral-n2': '#1f201f',
  'neutral-n3': '#373836',
  'neutral-n4': '#4f504f',
  'neutral-n5': '#676866',
  'neutral-n6': '#7f807f',
  'neutral-n7': '#979896',
  'neutral-n8': '#aeafae',
  'neutral-n9': '#c7c8c6',
  'neutral-n10': '#dedfde',
  'neutral-n11': '#f6f8f6',
  'neutral-n12': '#ffffff',
  'semantic-success': '#679b85',
  'semantic-warning': '#fed16b',
  'semantic-error': '#f8864b',
}

export type Color = keyof typeof colors

export const castColor = (colorName: Color) => colors[colorName]
