import { type ComponentPropsWithoutRef } from 'react'
import { cva, cx } from 'cva'

const variants = cva(['select-none', 'font-medium', 'text-neutral-n6'], {
  variants: {
    invalid: {
      true: 'text-semantic-error',
      false: 'text-neutral-n4',
    },
  },
  defaultVariants: {
    invalid: false,
  },
})

type InlineTextProps = ComponentPropsWithoutRef<'span'> & {
  invalid?: boolean
}

export const InlineText = ({
  children = null,
  className = '',
  invalid = false,
}: InlineTextProps) => (
  <span className={cx(variants({ invalid }), className)}>{children}</span>
)
