import { type ChangeEvent, forwardRef } from 'react'
import { cva, cx } from 'cva'
import type { RefCallBack } from 'react-hook-form/dist/types'
import type { WithInlineTextProps } from '../controller-inputs/types/with-inline-text-props'
import { Container } from './support-components/container'
import { ErrorMessage } from './support-components/error-message'
import { InlineText } from './support-components/inline-text'
import { InlineWrapper } from './support-components/inline-wrapper'
import { Label } from './support-components/label'
import { LabelText } from './support-components/label-text'
import type { BaseInputProps } from './types/base-input-props'

const bgVariants = cva('', {
  variants: {
    checked: {
      true: '',
    },
    invalid: {
      true: '',
    },
  },
  compoundVariants: [
    {
      checked: false,
      invalid: false,
      class: 'stroke-neutral-n10',
    },
    {
      checked: false,
      invalid: true,
      class: 'stroke-semantic-error',
    },
    {
      checked: true,
      invalid: false,
      class: 'stroke-primary-p-0 fill-primary-p-0',
    },
    {
      checked: true,
      invalid: true,
      class: 'stroke-semantic-error fill-semantic-error',
    },
  ],
  defaultVariants: {
    checked: false,
    invalid: false,
  },
})

const checkMarkVariants = cva('path transition-all duration-150', {
  variants: {
    checked: {
      true: '-checked',
    },
  },
  defaultVariants: {
    checked: false,
  },
})

// TODO rename to InputCheckboxProps? Check internet before chaging
export type InputCheckBoxProps = BaseInputProps<'input'> &
  WithInlineTextProps & {
    checked?: boolean
    onChange?: (checked: boolean) => void
  }

// TODO implement accessibility
// TODO on click label, it shouldn't be changed, just fosuced
// TODO should have an outline when focused
//      and could hit space bar to change it
export const InputCheckbox = forwardRef(
  (
    {
      checked = false,
      className = '',
      classNameErrorMessage = '',
      classNameInlineText = '',
      classNameInput = '',
      classNameLabel = '',
      errorMessage = '',
      inlineText = '',
      label = '',
      onChange = () => {},
      ...otherProps
    }: InputCheckBoxProps,
    ref
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked)
    }

    return (
      <Container className={cx('input-checkbox', className)}>
        <Label className={classNameLabel}>
          {!!label && <LabelText invalid={!!errorMessage}>{label}</LabelText>}

          <InlineWrapper>
            <input
              className="hidden"
              checked={checked}
              type="checkbox"
              onChange={handleChange}
              {...otherProps}
              ref={ref as RefCallBack}
            />

            <svg
              className={classNameInput}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                className={bgVariants({ checked, invalid: !!errorMessage })}
                x="2"
                y="2"
                width="20"
                height="20"
                rx="4"
                strokeWidth="2"
              />

              <path
                className={checkMarkVariants({ checked })}
                d="M7 12L10 15L17 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            {!!inlineText && (
              <InlineText
                className={classNameInlineText}
                invalid={!!errorMessage}
              >
                {inlineText}
              </InlineText>
            )}
          </InlineWrapper>
        </Label>

        {!!errorMessage && errorMessage !== '*' && (
          <ErrorMessage className={classNameErrorMessage}>
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    )
  }
)
