import { Controller, type FieldValues } from 'react-hook-form'
import {
  InputCheckbox,
  type InputCheckBoxProps,
} from '@components/ui/inputs/base-inputs/input-checkbox'
import { BaseControllerProps } from './types/base-controller-props'

export const InputCheckboxController = <TFormSchema extends FieldValues>({
  control,
  name,
  ...externalProps
}: BaseControllerProps<InputCheckBoxProps, TFormSchema>) => {
  return (
    <Controller
      control={control}
      name={name as string}
      render={({ field: { value, ...controllerProps }, fieldState }) => (
        <InputCheckbox
          {...{ ...externalProps, ...controllerProps }}
          checked={value}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  )
}
