import { type HTMLProps } from 'react'

type IconProps = HTMLProps<SVGSVGElement>

const defaultSize = 24

export function MangoName({
  width = defaultSize,
  height = defaultSize,
  size = 0,
  ...otherProps
}: IconProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 54 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M2.02643 13.1667C1.61633 13.1667 1.2985 13.0525 1.07295 12.8242C0.85765 12.5958 0.75 12.2741 0.75 11.859V3.21884C0.75 2.79332 0.867903 2.47159 1.10371 2.25364C1.33951 2.02531 1.67272 1.91115 2.10332 1.91115C2.46216 1.91115 2.7441 1.9838 2.94915 2.1291C3.16445 2.26402 3.35924 2.49234 3.53353 2.81408L6.83994 8.82325H6.30169L9.59272 2.81408C9.77726 2.49234 9.97206 2.26402 10.1771 2.1291C10.3924 1.9838 10.6795 1.91115 11.0383 1.91115C11.4484 1.91115 11.7611 2.02531 11.9764 2.25364C12.202 2.47159 12.3147 2.79332 12.3147 3.21884V11.859C12.3147 12.2741 12.2071 12.5958 11.9918 12.8242C11.7765 13.0525 11.4587 13.1667 11.0383 13.1667C10.6282 13.1667 10.3104 13.0525 10.0848 12.8242C9.86953 12.5958 9.76188 12.2741 9.76188 11.859V6.50365H10.0848L7.60887 10.8626C7.46534 11.091 7.31155 11.2622 7.14751 11.3764C6.99373 11.4905 6.78355 11.5476 6.51699 11.5476C6.26068 11.5476 6.0505 11.4905 5.88646 11.3764C5.72243 11.2622 5.57377 11.091 5.44048 10.8626L2.94915 6.48808H3.30285V11.859C3.30285 12.2741 3.1952 12.5958 2.9799 12.8242C2.7646 13.0525 2.44678 13.1667 2.02643 13.1667Z"
        fill="white"
      />
      <path
        d="M2.02643 13.1667C1.61633 13.1667 1.2985 13.0525 1.07295 12.8242C0.85765 12.5958 0.75 12.2741 0.75 11.859V3.21884C0.75 2.79332 0.867903 2.47159 1.10371 2.25364C1.33951 2.02531 1.67272 1.91115 2.10332 1.91115C2.46216 1.91115 2.7441 1.9838 2.94915 2.1291C3.16445 2.26402 3.35924 2.49234 3.53353 2.81408L6.83994 8.82325H6.30169L9.59272 2.81408C9.77726 2.49234 9.97206 2.26402 10.1771 2.1291C10.3924 1.9838 10.6795 1.91115 11.0383 1.91115C11.4484 1.91115 11.7611 2.02531 11.9764 2.25364C12.202 2.47159 12.3147 2.79332 12.3147 3.21884V11.859C12.3147 12.2741 12.2071 12.5958 11.9918 12.8242C11.7765 13.0525 11.4587 13.1667 11.0383 13.1667C10.6282 13.1667 10.3104 13.0525 10.0848 12.8242C9.86953 12.5958 9.76188 12.2741 9.76188 11.859V6.50365H10.0848L7.60887 10.8626C7.46534 11.091 7.31155 11.2622 7.14751 11.3764C6.99373 11.4905 6.78355 11.5476 6.51699 11.5476C6.26068 11.5476 6.0505 11.4905 5.88646 11.3764C5.72243 11.2622 5.57377 11.091 5.44048 10.8626L2.94915 6.48808H3.30285V11.859C3.30285 12.2741 3.1952 12.5958 2.9799 12.8242C2.7646 13.0525 2.44678 13.1667 2.02643 13.1667Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M2.02643 13.1667C1.61633 13.1667 1.2985 13.0525 1.07295 12.8242C0.85765 12.5958 0.75 12.2741 0.75 11.859V3.21884C0.75 2.79332 0.867903 2.47159 1.10371 2.25364C1.33951 2.02531 1.67272 1.91115 2.10332 1.91115C2.46216 1.91115 2.7441 1.9838 2.94915 2.1291C3.16445 2.26402 3.35924 2.49234 3.53353 2.81408L6.83994 8.82325H6.30169L9.59272 2.81408C9.77726 2.49234 9.97206 2.26402 10.1771 2.1291C10.3924 1.9838 10.6795 1.91115 11.0383 1.91115C11.4484 1.91115 11.7611 2.02531 11.9764 2.25364C12.202 2.47159 12.3147 2.79332 12.3147 3.21884V11.859C12.3147 12.2741 12.2071 12.5958 11.9918 12.8242C11.7765 13.0525 11.4587 13.1667 11.0383 13.1667C10.6282 13.1667 10.3104 13.0525 10.0848 12.8242C9.86953 12.5958 9.76188 12.2741 9.76188 11.859V6.50365H10.0848L7.60887 10.8626C7.46534 11.091 7.31155 11.2622 7.14751 11.3764C6.99373 11.4905 6.78355 11.5476 6.51699 11.5476C6.26068 11.5476 6.0505 11.4905 5.88646 11.3764C5.72243 11.2622 5.57377 11.091 5.44048 10.8626L2.94915 6.48808H3.30285V11.859C3.30285 12.2741 3.1952 12.5958 2.9799 12.8242C2.7646 13.0525 2.44678 13.1667 2.02643 13.1667Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
      <path
        d="M16.7559 13.1978C16.1715 13.1978 15.6538 13.0888 15.2027 12.8709C14.7618 12.6529 14.4132 12.3571 14.1569 11.9835C13.9109 11.5995 13.7878 11.1636 13.7878 10.6758C13.7878 10.1154 13.9314 9.67429 14.2184 9.35255C14.5055 9.03082 14.9669 8.80249 15.6025 8.66757C16.2382 8.52227 17.084 8.44962 18.14 8.44962H18.9858V9.75731H18.14C17.7402 9.75731 17.4069 9.78845 17.1404 9.85072C16.8841 9.90261 16.6944 9.99083 16.5714 10.1154C16.4483 10.2295 16.3868 10.38 16.3868 10.5668C16.3868 10.8055 16.4688 11.0027 16.6329 11.1584C16.8072 11.3141 17.0532 11.3919 17.3711 11.3919C17.6274 11.3919 17.8529 11.3348 18.0477 11.2207C18.2528 11.1065 18.4168 10.9508 18.5398 10.7537C18.6629 10.5461 18.7244 10.3074 18.7244 10.0375V8.23167C18.7244 7.85804 18.627 7.59339 18.4322 7.43771C18.2476 7.28203 17.9247 7.2042 17.4633 7.2042C17.2173 7.2042 16.9405 7.23533 16.6329 7.2976C16.3253 7.3495 15.9819 7.44809 15.6025 7.59339C15.3257 7.69718 15.0899 7.71274 14.8951 7.64009C14.7003 7.56744 14.5465 7.4429 14.4337 7.26647C14.3312 7.09003 14.28 6.89803 14.28 6.69046C14.2902 6.48289 14.3568 6.2857 14.4799 6.09888C14.6132 5.91207 14.8131 5.76677 15.0796 5.66298C15.5718 5.47617 16.0229 5.35163 16.433 5.28936C16.8533 5.22709 17.2378 5.19595 17.5864 5.19595C18.4271 5.19595 19.1191 5.32049 19.6625 5.56958C20.2161 5.80828 20.6262 6.18191 20.8928 6.69046C21.1696 7.18863 21.308 7.8321 21.308 8.62087V11.7656C21.308 12.2222 21.2003 12.5699 20.985 12.8086C20.7697 13.0473 20.457 13.1667 20.0469 13.1667C19.6266 13.1667 19.3036 13.0473 19.0781 12.8086C18.8628 12.5699 18.7551 12.2222 18.7551 11.7656V11.3608L18.8474 11.5787C18.7961 11.9109 18.6782 12.1963 18.4937 12.435C18.3092 12.6737 18.0682 12.8605 17.7709 12.9954C17.4736 13.1303 17.1353 13.1978 16.7559 13.1978Z"
        fill="white"
      />
      <path
        d="M16.7559 13.1978C16.1715 13.1978 15.6538 13.0888 15.2027 12.8709C14.7618 12.6529 14.4132 12.3571 14.1569 11.9835C13.9109 11.5995 13.7878 11.1636 13.7878 10.6758C13.7878 10.1154 13.9314 9.67429 14.2184 9.35255C14.5055 9.03082 14.9669 8.80249 15.6025 8.66757C16.2382 8.52227 17.084 8.44962 18.14 8.44962H18.9858V9.75731H18.14C17.7402 9.75731 17.4069 9.78845 17.1404 9.85072C16.8841 9.90261 16.6944 9.99083 16.5714 10.1154C16.4483 10.2295 16.3868 10.38 16.3868 10.5668C16.3868 10.8055 16.4688 11.0027 16.6329 11.1584C16.8072 11.3141 17.0532 11.3919 17.3711 11.3919C17.6274 11.3919 17.8529 11.3348 18.0477 11.2207C18.2528 11.1065 18.4168 10.9508 18.5398 10.7537C18.6629 10.5461 18.7244 10.3074 18.7244 10.0375V8.23167C18.7244 7.85804 18.627 7.59339 18.4322 7.43771C18.2476 7.28203 17.9247 7.2042 17.4633 7.2042C17.2173 7.2042 16.9405 7.23533 16.6329 7.2976C16.3253 7.3495 15.9819 7.44809 15.6025 7.59339C15.3257 7.69718 15.0899 7.71274 14.8951 7.64009C14.7003 7.56744 14.5465 7.4429 14.4337 7.26647C14.3312 7.09003 14.28 6.89803 14.28 6.69046C14.2902 6.48289 14.3568 6.2857 14.4799 6.09888C14.6132 5.91207 14.8131 5.76677 15.0796 5.66298C15.5718 5.47617 16.0229 5.35163 16.433 5.28936C16.8533 5.22709 17.2378 5.19595 17.5864 5.19595C18.4271 5.19595 19.1191 5.32049 19.6625 5.56958C20.2161 5.80828 20.6262 6.18191 20.8928 6.69046C21.1696 7.18863 21.308 7.8321 21.308 8.62087V11.7656C21.308 12.2222 21.2003 12.5699 20.985 12.8086C20.7697 13.0473 20.457 13.1667 20.0469 13.1667C19.6266 13.1667 19.3036 13.0473 19.0781 12.8086C18.8628 12.5699 18.7551 12.2222 18.7551 11.7656V11.3608L18.8474 11.5787C18.7961 11.9109 18.6782 12.1963 18.4937 12.435C18.3092 12.6737 18.0682 12.8605 17.7709 12.9954C17.4736 13.1303 17.1353 13.1978 16.7559 13.1978Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M16.7559 13.1978C16.1715 13.1978 15.6538 13.0888 15.2027 12.8709C14.7618 12.6529 14.4132 12.3571 14.1569 11.9835C13.9109 11.5995 13.7878 11.1636 13.7878 10.6758C13.7878 10.1154 13.9314 9.67429 14.2184 9.35255C14.5055 9.03082 14.9669 8.80249 15.6025 8.66757C16.2382 8.52227 17.084 8.44962 18.14 8.44962H18.9858V9.75731H18.14C17.7402 9.75731 17.4069 9.78845 17.1404 9.85072C16.8841 9.90261 16.6944 9.99083 16.5714 10.1154C16.4483 10.2295 16.3868 10.38 16.3868 10.5668C16.3868 10.8055 16.4688 11.0027 16.6329 11.1584C16.8072 11.3141 17.0532 11.3919 17.3711 11.3919C17.6274 11.3919 17.8529 11.3348 18.0477 11.2207C18.2528 11.1065 18.4168 10.9508 18.5398 10.7537C18.6629 10.5461 18.7244 10.3074 18.7244 10.0375V8.23167C18.7244 7.85804 18.627 7.59339 18.4322 7.43771C18.2476 7.28203 17.9247 7.2042 17.4633 7.2042C17.2173 7.2042 16.9405 7.23533 16.6329 7.2976C16.3253 7.3495 15.9819 7.44809 15.6025 7.59339C15.3257 7.69718 15.0899 7.71274 14.8951 7.64009C14.7003 7.56744 14.5465 7.4429 14.4337 7.26647C14.3312 7.09003 14.28 6.89803 14.28 6.69046C14.2902 6.48289 14.3568 6.2857 14.4799 6.09888C14.6132 5.91207 14.8131 5.76677 15.0796 5.66298C15.5718 5.47617 16.0229 5.35163 16.433 5.28936C16.8533 5.22709 17.2378 5.19595 17.5864 5.19595C18.4271 5.19595 19.1191 5.32049 19.6625 5.56958C20.2161 5.80828 20.6262 6.18191 20.8928 6.69046C21.1696 7.18863 21.308 7.8321 21.308 8.62087V11.7656C21.308 12.2222 21.2003 12.5699 20.985 12.8086C20.7697 13.0473 20.457 13.1667 20.0469 13.1667C19.6266 13.1667 19.3036 13.0473 19.0781 12.8086C18.8628 12.5699 18.7551 12.2222 18.7551 11.7656V11.3608L18.8474 11.5787C18.7961 11.9109 18.6782 12.1963 18.4937 12.435C18.3092 12.6737 18.0682 12.8605 17.7709 12.9954C17.4736 13.1303 17.1353 13.1978 16.7559 13.1978Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
      <path
        d="M24.2201 13.1667C23.7895 13.1667 23.4563 13.0473 23.2205 12.8086C22.9847 12.5699 22.8668 12.2222 22.8668 11.7656V6.61262C22.8668 6.16634 22.9795 5.82385 23.2051 5.58515C23.4409 5.34644 23.7741 5.22709 24.2047 5.22709C24.6353 5.22709 24.9634 5.34644 25.1889 5.58515C25.4145 5.82385 25.5273 6.16634 25.5273 6.61262V7.34431L25.3581 6.70603C25.6042 6.21824 25.9527 5.84461 26.4038 5.58515C26.8652 5.32568 27.3881 5.19595 27.9725 5.19595C28.5774 5.19595 29.0746 5.3153 29.4642 5.55401C29.8538 5.78234 30.146 6.14559 30.3408 6.64376C30.5356 7.13155 30.633 7.74907 30.633 8.49632V11.7656C30.633 12.2222 30.5151 12.5699 30.2793 12.8086C30.0435 13.0473 29.7051 13.1667 29.2643 13.1667C28.8234 13.1667 28.4851 13.0473 28.2493 12.8086C28.0135 12.5699 27.8956 12.2222 27.8956 11.7656V8.6053C27.8956 8.13826 27.8136 7.80615 27.6495 7.60896C27.4957 7.41177 27.2651 7.31317 26.9575 7.31317C26.5474 7.31317 26.2193 7.44809 25.9732 7.71793C25.7272 7.9774 25.6042 8.33027 25.6042 8.77654V11.7656C25.6042 12.6996 25.1428 13.1667 24.2201 13.1667Z"
        fill="white"
      />
      <path
        d="M24.2201 13.1667C23.7895 13.1667 23.4563 13.0473 23.2205 12.8086C22.9847 12.5699 22.8668 12.2222 22.8668 11.7656V6.61262C22.8668 6.16634 22.9795 5.82385 23.2051 5.58515C23.4409 5.34644 23.7741 5.22709 24.2047 5.22709C24.6353 5.22709 24.9634 5.34644 25.1889 5.58515C25.4145 5.82385 25.5273 6.16634 25.5273 6.61262V7.34431L25.3581 6.70603C25.6042 6.21824 25.9527 5.84461 26.4038 5.58515C26.8652 5.32568 27.3881 5.19595 27.9725 5.19595C28.5774 5.19595 29.0746 5.3153 29.4642 5.55401C29.8538 5.78234 30.146 6.14559 30.3408 6.64376C30.5356 7.13155 30.633 7.74907 30.633 8.49632V11.7656C30.633 12.2222 30.5151 12.5699 30.2793 12.8086C30.0435 13.0473 29.7051 13.1667 29.2643 13.1667C28.8234 13.1667 28.4851 13.0473 28.2493 12.8086C28.0135 12.5699 27.8956 12.2222 27.8956 11.7656V8.6053C27.8956 8.13826 27.8136 7.80615 27.6495 7.60896C27.4957 7.41177 27.2651 7.31317 26.9575 7.31317C26.5474 7.31317 26.2193 7.44809 25.9732 7.71793C25.7272 7.9774 25.6042 8.33027 25.6042 8.77654V11.7656C25.6042 12.6996 25.1428 13.1667 24.2201 13.1667Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M24.2201 13.1667C23.7895 13.1667 23.4563 13.0473 23.2205 12.8086C22.9847 12.5699 22.8668 12.2222 22.8668 11.7656V6.61262C22.8668 6.16634 22.9795 5.82385 23.2051 5.58515C23.4409 5.34644 23.7741 5.22709 24.2047 5.22709C24.6353 5.22709 24.9634 5.34644 25.1889 5.58515C25.4145 5.82385 25.5273 6.16634 25.5273 6.61262V7.34431L25.3581 6.70603C25.6042 6.21824 25.9527 5.84461 26.4038 5.58515C26.8652 5.32568 27.3881 5.19595 27.9725 5.19595C28.5774 5.19595 29.0746 5.3153 29.4642 5.55401C29.8538 5.78234 30.146 6.14559 30.3408 6.64376C30.5356 7.13155 30.633 7.74907 30.633 8.49632V11.7656C30.633 12.2222 30.5151 12.5699 30.2793 12.8086C30.0435 13.0473 29.7051 13.1667 29.2643 13.1667C28.8234 13.1667 28.4851 13.0473 28.2493 12.8086C28.0135 12.5699 27.8956 12.2222 27.8956 11.7656V8.6053C27.8956 8.13826 27.8136 7.80615 27.6495 7.60896C27.4957 7.41177 27.2651 7.31317 26.9575 7.31317C26.5474 7.31317 26.2193 7.44809 25.9732 7.71793C25.7272 7.9774 25.6042 8.33027 25.6042 8.77654V11.7656C25.6042 12.6996 25.1428 13.1667 24.2201 13.1667Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
      <path
        d="M35.9455 16C35.3816 16 34.8536 15.9585 34.3615 15.8755C33.8693 15.8028 33.4439 15.6938 33.085 15.5485C32.7877 15.4447 32.5775 15.2943 32.4545 15.0971C32.3315 14.8999 32.2751 14.6923 32.2853 14.4744C32.2956 14.2564 32.352 14.0592 32.4545 13.8828C32.5673 13.7063 32.7159 13.5766 32.9005 13.4936C33.085 13.4209 33.2901 13.4261 33.5156 13.5092C33.9667 13.696 34.3768 13.8153 34.7459 13.8672C35.115 13.9295 35.4277 13.9606 35.684 13.9606C36.3094 13.9606 36.7759 13.8257 37.0835 13.5559C37.4013 13.2964 37.5602 12.8864 37.5602 12.326V11.3452H37.6832C37.5294 11.7811 37.2168 12.1392 36.7451 12.4194C36.2735 12.6892 35.7609 12.8242 35.2073 12.8242C34.5306 12.8242 33.9411 12.6685 33.4387 12.3571C32.9364 12.0354 32.5416 11.5891 32.2546 11.0183C31.9778 10.4475 31.8394 9.77807 31.8394 9.01006C31.8394 8.42886 31.9214 7.90475 32.0854 7.43771C32.2495 6.97068 32.475 6.57111 32.7621 6.23899C33.0594 5.90688 33.4131 5.65261 33.8232 5.47617C34.2435 5.28936 34.7049 5.19595 35.2073 5.19595C35.7814 5.19595 36.294 5.33087 36.7451 5.60071C37.2065 5.87055 37.5141 6.22342 37.6679 6.65932L37.5141 7.2976V6.61262C37.5141 6.16634 37.632 5.82385 37.8678 5.58515C38.1138 5.34644 38.4522 5.22709 38.8828 5.22709C39.3134 5.22709 39.6415 5.34644 39.867 5.58515C40.1028 5.82385 40.2207 6.16634 40.2207 6.61262V11.9991C40.2207 13.3275 39.8516 14.3239 39.1135 14.9881C38.3753 15.6627 37.3193 16 35.9455 16ZM36.0531 10.7848C36.3607 10.7848 36.6221 10.7173 36.8374 10.5824C37.0527 10.4371 37.2219 10.2347 37.3449 9.97526C37.4679 9.70542 37.5295 9.38369 37.5295 9.01006C37.5295 8.44962 37.3962 8.01372 37.1296 7.70237C36.863 7.39101 36.5042 7.23533 36.0531 7.23533C35.766 7.23533 35.5097 7.30798 35.2842 7.45328C35.0586 7.5882 34.8843 7.79058 34.7613 8.06042C34.6485 8.31989 34.5921 8.63643 34.5921 9.01006C34.5921 9.5705 34.7254 10.0064 34.992 10.3178C35.2585 10.6291 35.6122 10.7848 36.0531 10.7848Z"
        fill="white"
      />
      <path
        d="M35.9455 16C35.3816 16 34.8536 15.9585 34.3615 15.8755C33.8693 15.8028 33.4439 15.6938 33.085 15.5485C32.7877 15.4447 32.5775 15.2943 32.4545 15.0971C32.3315 14.8999 32.2751 14.6923 32.2853 14.4744C32.2956 14.2564 32.352 14.0592 32.4545 13.8828C32.5673 13.7063 32.7159 13.5766 32.9005 13.4936C33.085 13.4209 33.2901 13.4261 33.5156 13.5092C33.9667 13.696 34.3768 13.8153 34.7459 13.8672C35.115 13.9295 35.4277 13.9606 35.684 13.9606C36.3094 13.9606 36.7759 13.8257 37.0835 13.5559C37.4013 13.2964 37.5602 12.8864 37.5602 12.326V11.3452H37.6832C37.5294 11.7811 37.2168 12.1392 36.7451 12.4194C36.2735 12.6892 35.7609 12.8242 35.2073 12.8242C34.5306 12.8242 33.9411 12.6685 33.4387 12.3571C32.9364 12.0354 32.5416 11.5891 32.2546 11.0183C31.9778 10.4475 31.8394 9.77807 31.8394 9.01006C31.8394 8.42886 31.9214 7.90475 32.0854 7.43771C32.2495 6.97068 32.475 6.57111 32.7621 6.23899C33.0594 5.90688 33.4131 5.65261 33.8232 5.47617C34.2435 5.28936 34.7049 5.19595 35.2073 5.19595C35.7814 5.19595 36.294 5.33087 36.7451 5.60071C37.2065 5.87055 37.5141 6.22342 37.6679 6.65932L37.5141 7.2976V6.61262C37.5141 6.16634 37.632 5.82385 37.8678 5.58515C38.1138 5.34644 38.4522 5.22709 38.8828 5.22709C39.3134 5.22709 39.6415 5.34644 39.867 5.58515C40.1028 5.82385 40.2207 6.16634 40.2207 6.61262V11.9991C40.2207 13.3275 39.8516 14.3239 39.1135 14.9881C38.3753 15.6627 37.3193 16 35.9455 16ZM36.0531 10.7848C36.3607 10.7848 36.6221 10.7173 36.8374 10.5824C37.0527 10.4371 37.2219 10.2347 37.3449 9.97526C37.4679 9.70542 37.5295 9.38369 37.5295 9.01006C37.5295 8.44962 37.3962 8.01372 37.1296 7.70237C36.863 7.39101 36.5042 7.23533 36.0531 7.23533C35.766 7.23533 35.5097 7.30798 35.2842 7.45328C35.0586 7.5882 34.8843 7.79058 34.7613 8.06042C34.6485 8.31989 34.5921 8.63643 34.5921 9.01006C34.5921 9.5705 34.7254 10.0064 34.992 10.3178C35.2585 10.6291 35.6122 10.7848 36.0531 10.7848Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M35.9455 16C35.3816 16 34.8536 15.9585 34.3615 15.8755C33.8693 15.8028 33.4439 15.6938 33.085 15.5485C32.7877 15.4447 32.5775 15.2943 32.4545 15.0971C32.3315 14.8999 32.2751 14.6923 32.2853 14.4744C32.2956 14.2564 32.352 14.0592 32.4545 13.8828C32.5673 13.7063 32.7159 13.5766 32.9005 13.4936C33.085 13.4209 33.2901 13.4261 33.5156 13.5092C33.9667 13.696 34.3768 13.8153 34.7459 13.8672C35.115 13.9295 35.4277 13.9606 35.684 13.9606C36.3094 13.9606 36.7759 13.8257 37.0835 13.5559C37.4013 13.2964 37.5602 12.8864 37.5602 12.326V11.3452H37.6832C37.5294 11.7811 37.2168 12.1392 36.7451 12.4194C36.2735 12.6892 35.7609 12.8242 35.2073 12.8242C34.5306 12.8242 33.9411 12.6685 33.4387 12.3571C32.9364 12.0354 32.5416 11.5891 32.2546 11.0183C31.9778 10.4475 31.8394 9.77807 31.8394 9.01006C31.8394 8.42886 31.9214 7.90475 32.0854 7.43771C32.2495 6.97068 32.475 6.57111 32.7621 6.23899C33.0594 5.90688 33.4131 5.65261 33.8232 5.47617C34.2435 5.28936 34.7049 5.19595 35.2073 5.19595C35.7814 5.19595 36.294 5.33087 36.7451 5.60071C37.2065 5.87055 37.5141 6.22342 37.6679 6.65932L37.5141 7.2976V6.61262C37.5141 6.16634 37.632 5.82385 37.8678 5.58515C38.1138 5.34644 38.4522 5.22709 38.8828 5.22709C39.3134 5.22709 39.6415 5.34644 39.867 5.58515C40.1028 5.82385 40.2207 6.16634 40.2207 6.61262V11.9991C40.2207 13.3275 39.8516 14.3239 39.1135 14.9881C38.3753 15.6627 37.3193 16 35.9455 16ZM36.0531 10.7848C36.3607 10.7848 36.6221 10.7173 36.8374 10.5824C37.0527 10.4371 37.2219 10.2347 37.3449 9.97526C37.4679 9.70542 37.5295 9.38369 37.5295 9.01006C37.5295 8.44962 37.3962 8.01372 37.1296 7.70237C36.863 7.39101 36.5042 7.23533 36.0531 7.23533C35.766 7.23533 35.5097 7.30798 35.2842 7.45328C35.0586 7.5882 34.8843 7.79058 34.7613 8.06042C34.6485 8.31989 34.5921 8.63643 34.5921 9.01006C34.5921 9.5705 34.7254 10.0064 34.992 10.3178C35.2585 10.6291 35.6122 10.7848 36.0531 10.7848Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
      <path
        d="M45.649 13.1978C44.8083 13.1978 44.0753 13.0369 43.4499 12.7152C42.8245 12.3935 42.3375 11.9316 41.9889 11.3297C41.6403 10.7277 41.466 10.0168 41.466 9.19687C41.466 8.57416 41.5634 8.01891 41.7582 7.53112C41.953 7.03295 42.235 6.61262 42.604 6.27013C42.9731 5.91726 43.414 5.65261 43.9266 5.47617C44.4392 5.28936 45.0134 5.19595 45.649 5.19595C46.4897 5.19595 47.2228 5.35682 47.8482 5.67855C48.4736 6.00029 48.9554 6.46213 49.2937 7.06409C49.6423 7.65566 49.8166 8.36659 49.8166 9.19687C49.8166 9.80921 49.7192 10.3645 49.5244 10.8626C49.3296 11.3608 49.0477 11.7863 48.6786 12.1392C48.3198 12.4817 47.8789 12.7463 47.356 12.9331C46.8434 13.1096 46.2744 13.1978 45.649 13.1978ZM45.649 11.1584C45.9258 11.1584 46.1719 11.091 46.3872 10.956C46.6025 10.8107 46.7665 10.598 46.8793 10.3178C47.0023 10.0272 47.0638 9.65353 47.0638 9.19687C47.0638 8.50151 46.9306 8.00334 46.664 7.70237C46.3974 7.39101 46.0591 7.23533 45.649 7.23533C45.3722 7.23533 45.1261 7.30279 44.9108 7.43771C44.6955 7.57263 44.5264 7.78539 44.4033 8.07599C44.2803 8.35621 44.2188 8.72984 44.2188 9.19687C44.2188 9.88186 44.3521 10.38 44.6186 10.6914C44.8852 11.0027 45.2287 11.1584 45.649 11.1584Z"
        fill="white"
      />
      <path
        d="M45.649 13.1978C44.8083 13.1978 44.0753 13.0369 43.4499 12.7152C42.8245 12.3935 42.3375 11.9316 41.9889 11.3297C41.6403 10.7277 41.466 10.0168 41.466 9.19687C41.466 8.57416 41.5634 8.01891 41.7582 7.53112C41.953 7.03295 42.235 6.61262 42.604 6.27013C42.9731 5.91726 43.414 5.65261 43.9266 5.47617C44.4392 5.28936 45.0134 5.19595 45.649 5.19595C46.4897 5.19595 47.2228 5.35682 47.8482 5.67855C48.4736 6.00029 48.9554 6.46213 49.2937 7.06409C49.6423 7.65566 49.8166 8.36659 49.8166 9.19687C49.8166 9.80921 49.7192 10.3645 49.5244 10.8626C49.3296 11.3608 49.0477 11.7863 48.6786 12.1392C48.3198 12.4817 47.8789 12.7463 47.356 12.9331C46.8434 13.1096 46.2744 13.1978 45.649 13.1978ZM45.649 11.1584C45.9258 11.1584 46.1719 11.091 46.3872 10.956C46.6025 10.8107 46.7665 10.598 46.8793 10.3178C47.0023 10.0272 47.0638 9.65353 47.0638 9.19687C47.0638 8.50151 46.9306 8.00334 46.664 7.70237C46.3974 7.39101 46.0591 7.23533 45.649 7.23533C45.3722 7.23533 45.1261 7.30279 44.9108 7.43771C44.6955 7.57263 44.5264 7.78539 44.4033 8.07599C44.2803 8.35621 44.2188 8.72984 44.2188 9.19687C44.2188 9.88186 44.3521 10.38 44.6186 10.6914C44.8852 11.0027 45.2287 11.1584 45.649 11.1584Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M45.649 13.1978C44.8083 13.1978 44.0753 13.0369 43.4499 12.7152C42.8245 12.3935 42.3375 11.9316 41.9889 11.3297C41.6403 10.7277 41.466 10.0168 41.466 9.19687C41.466 8.57416 41.5634 8.01891 41.7582 7.53112C41.953 7.03295 42.235 6.61262 42.604 6.27013C42.9731 5.91726 43.414 5.65261 43.9266 5.47617C44.4392 5.28936 45.0134 5.19595 45.649 5.19595C46.4897 5.19595 47.2228 5.35682 47.8482 5.67855C48.4736 6.00029 48.9554 6.46213 49.2937 7.06409C49.6423 7.65566 49.8166 8.36659 49.8166 9.19687C49.8166 9.80921 49.7192 10.3645 49.5244 10.8626C49.3296 11.3608 49.0477 11.7863 48.6786 12.1392C48.3198 12.4817 47.8789 12.7463 47.356 12.9331C46.8434 13.1096 46.2744 13.1978 45.649 13.1978ZM45.649 11.1584C45.9258 11.1584 46.1719 11.091 46.3872 10.956C46.6025 10.8107 46.7665 10.598 46.8793 10.3178C47.0023 10.0272 47.0638 9.65353 47.0638 9.19687C47.0638 8.50151 46.9306 8.00334 46.664 7.70237C46.3974 7.39101 46.0591 7.23533 45.649 7.23533C45.3722 7.23533 45.1261 7.30279 44.9108 7.43771C44.6955 7.57263 44.5264 7.78539 44.4033 8.07599C44.2803 8.35621 44.2188 8.72984 44.2188 9.19687C44.2188 9.88186 44.3521 10.38 44.6186 10.6914C44.8852 11.0027 45.2287 11.1584 45.649 11.1584Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
      <path
        d="M48.9097 4.89979C50.1924 5.24653 51.6177 4.91059 52.6239 3.89195C53.6302 2.87331 53.962 1.4305 53.6195 0.132085C52.3369 -0.214658 50.9116 0.121288 49.9053 1.13993C48.8991 2.15856 48.5672 3.60137 48.9097 4.89979Z"
        fill="white"
      />
      <path
        d="M48.9097 4.89979C50.1924 5.24653 51.6177 4.91059 52.6239 3.89195C53.6302 2.87331 53.962 1.4305 53.6195 0.132085C52.3369 -0.214658 50.9116 0.121288 49.9053 1.13993C48.8991 2.15856 48.5672 3.60137 48.9097 4.89979Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M48.9097 4.89979C50.1924 5.24653 51.6177 4.91059 52.6239 3.89195C53.6302 2.87331 53.962 1.4305 53.6195 0.132085C52.3369 -0.214658 50.9116 0.121288 49.9053 1.13993C48.8991 2.15856 48.5672 3.60137 48.9097 4.89979Z"
        fill="#71836D"
        fillOpacity="0.06"
      />
    </svg>
  )
}
