import { cloneElement } from 'react'
import {
  TablerIconsProps,
  IconBook,
  IconQrcode,
  IconBuildingStore,
  IconEye,
  IconChevronDown,
  IconAdjustments,
  IconGripVertical,
  IconPencil,
  IconChevronUp,
  IconX,
  IconBrandPeanut,
  IconChevronsLeft,
  IconChevronLeft,
  IconChevronRight,
  IconChevronsRight,
  IconEggCracked,
  IconFish,
  IconLoader2,
  IconMilk,
  IconSearch,
  IconPlus,
  IconTrash,
  IconLogout,
  IconUser,
  IconEyeOff,
  IconFileSmile,
  IconMoodSmileBeam,
  IconPigMoney,
  IconPlant, // IconStarFilled,
  IconChartHistogram,
  IconGavel,
  IconMilkOff,
  IconAd2,
  IconMenu2,
  IconCircleArrowDown,
  IconMail,
  IconCheck,
  IconCalendar,
  IconCircleCheck,
  IconXboxX,
} from '@tabler/icons-react'
import { FaStar } from 'react-icons/fa'
import { type Color, colors } from '@config/colors'
import { Almonds } from './almonds'
import { Dyes } from './dyes'
import { Gluten } from './gluten'
import { IconMango } from './icon-mango'
import { Lactose } from './lactose'
import { Nuts } from './nuts'
import { Shrimp } from './shrimp'
import { Soy } from './soy'

const icon = {
  'building-store': <IconBuildingStore />,
  'chart-histogram': <IconChartHistogram />,
  'chevron-down': <IconChevronDown />,
  'chevrons-left': <IconChevronsLeft />,
  'chevron-left': <IconChevronLeft />,
  'chevron-right': <IconChevronRight />,
  'chevrons-right': <IconChevronsRight />,
  'chevron-up': <IconChevronUp />,
  'circle-arrow-down': <IconCircleArrowDown />,
  'eye-off': <IconEyeOff />,
  'file-smile': <IconFileSmile />,
  'grip-vertical': <IconGripVertical />,
  'milk-off': <IconMilkOff />,
  'mood-smile-beam': <IconMoodSmileBeam />,
  'pig-money': <IconPigMoney />,
  'qr-code': <IconQrcode />,
  'star-filled': <FaStar />,
  ad2: <IconAd2 />,
  adjustments: <IconAdjustments />,
  almonds: <Almonds />,
  calendar: <IconCalendar />,
  check: <IconCheck />,
  close: <IconX />,
  'circle-close': <IconXboxX />,
  'circle-check': <IconCircleCheck />,
  dyes: <Dyes />,
  egg: <IconEggCracked />,
  eye: <IconEye />,
  fish: <IconFish />,
  gavel: <IconGavel />,
  gluten: <Gluten />,
  lactose: <Lactose />,
  logout: <IconLogout />,
  mail: <IconMail />,
  mango: <IconMango />,
  menu: <IconBook />,
  menu2: <IconMenu2 />,
  milk: <IconMilk />,
  nuts: <Nuts />,
  peanuts: <IconBrandPeanut />,
  pencil: <IconPencil />,
  plant: <IconPlant />,
  plus: <IconPlus />,
  search: <IconSearch />,
  shrimp: <Shrimp />,
  soy: <Soy />,
  spinner: <IconLoader2 />,
  trash: <IconTrash />,
  user: <IconUser />,
}

export type IconName = keyof typeof icon

export type IconProps = TablerIconsProps & {
  color?: Color
  stroke?: Color
  fill?: Color
  name: IconName
}

export const Icon = ({
  color = 'neutral-n0',
  stroke = 'neutral-n0',
  fill = 'neutral-n0',
  name,
  size = 24,
  ...otherProps
}: IconProps) => {
  const Component = icon[name]
  return !Component
    ? null
    : cloneElement(Component, {
        color: colors[color],
        size,
        ...otherProps,
      })
}
