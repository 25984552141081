import { cva } from 'cva'

// FIXME when error, set outline and border fixed in 2px with semantic-error-light
//       there's no difference between hover and focus state
export const borderedInputVariants = cva(
  [
    'min-h-[40px]',
    // TODO talk about these paddings with Ozeias
    'py-[7px]',
    'px-[7px]',
    'rounded-lg',
    'border',
    'font-medium',
    'hover:outline',
    'hover:outline-input',
    'focus:outline',
    'focus:outline-input',
    'focus:outline-offset-0',
  ],
  {
    variants: {
      invalid: {
        true: [
          'text-semantic-error',
          'border-semantic-error',
          'placeholder:text-semantic-error',
          // FIXME label hovering is triggering input hovering
          'hover:outline-semantic-error-light',
          'hover:placeholder:text-semantic-error-light',
          'focus:outline-semantic-error-light',
        ],
        false: [
          'text-neutral-n6',
          'border-neutral-n10',
          'placeholder:text-neutral-n8',
          'hover:outline-neutral-n11',
          'hover:placeholder:text-neutral-n6',
          'focus:outline-neutral-n10',
        ],
      },
    },
    defaultVariants: {
      invalid: false,
    },
  }
)
