import type { ComponentPropsWithoutRef } from 'react'
import { cx } from 'cva'

export const Label = ({
  className = '',
  children = null,
  ...otherProps
}: ComponentPropsWithoutRef<'span'>) => (
  <label
    className={cx('flex select-none flex-col gap-1', className)}
    {...otherProps}
  >
    {children}
  </label>
)
