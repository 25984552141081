import { type ComponentPropsWithRef } from 'react'
import { cn } from '@utils/styles'

type ContainerProps = ComponentPropsWithRef<'div'> & {}

export const Container = ({
  className = '',
  children = null,
  ...otherProps
}: ContainerProps) => {
  return (
    <div
      className={cn(
        'mx-auto flex w-full max-w-[1440px] justify-between gap-4 p-4 align-middle sm:p-14 md:p-20 lg:p-28',
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  )
}
