import {
  HashRouter,
  Route,
  Routes as ReactRouterRoutes,
} from 'react-router-dom'
// import { FinishRegisterRoute } from '@components/routes/finish-register-route'
import { MainRoute } from '@components/routes/main-route'
// import { RegisterRoute } from '@components/routes/register-route'
import { UserRegisterRoute } from '@components/routes/user-register-route'

// import { VerifiedRoute } from '@components/routes/verified-route'

// TODO create nice error component
export function Routes() {
  return (
    <HashRouter>
      <ReactRouterRoutes>
        <Route index element={<MainRoute />} />
        <Route path="/home" element={<MainRoute />} />
        {/* <Route path="/register" element={<RegisterRoute />} /> */}
        {/* <Route path="/verify-email" element={<VerifiedRoute />} /> */}
        <Route path="/user-register" element={<UserRegisterRoute />} />
        {/* <Route path="/finish-register" element={<FinishRegisterRoute />} /> */}
        <Route path="*" element={<h1>Not found</h1>} />
      </ReactRouterRoutes>
    </HashRouter>
  )
}
