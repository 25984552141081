import type { ComponentPropsWithoutRef } from 'react'
import { cx } from 'cva'

export const ErrorMessage = ({
  children = null,
  className = '',
}: ComponentPropsWithoutRef<'span'>) => (
  <div
    className={cx(
      'h-4 text-xs font-medium tracking-wide text-semantic-error',
      className
    )}
  >
    {children}
  </div>
)
