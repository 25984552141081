export function validateCPF(cpf: string): boolean {
  // Remove non-numeric characters
  const cleanCPF = cpf.replace(/\D/g, '')

  // Check if CPF has 11 digits
  if (cleanCPF.length !== 11) {
    return false
  }

  // Check if all digits are the same
  if (/^(\d)\1+$/.test(cleanCPF)) {
    return false
  }

  // Validate CPF algorithm
  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (10 - i)
  }

  const remainder = (sum * 10) % 11
  const digit1 = remainder === 10 || remainder === 11 ? 0 : remainder

  if (digit1 !== parseInt(cleanCPF.charAt(9))) {
    return false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (11 - i)
  }

  const remainder2 = (sum * 10) % 11
  const digit2 = remainder2 === 10 || remainder2 === 11 ? 0 : remainder2

  return digit2 === parseInt(cleanCPF.charAt(10))
}
