import { cx } from 'cva'
import { RegisterButton } from '@components/routes/register-route'
import { Button } from '@components/ui/button'
import { Card } from '@components/ui/card'
import { Container } from '@components/ui/container'
import { GoTo } from '@components/ui/header'
import { Icon } from '@components/ui/icon'
import { Image } from '@components/ui/img'
import { scrollTo } from '@utils/scroll/scroll-to'

export function Start() {
  return (
    // h-[calc(100dvh-72px)]
    <Container id="start" className="relative max-md:flex-col">
      <div className="flex flex-1 flex-col gap-4 max-md:order-2">
        <span className="text-[40px] font-bold text-primary-p-0 max-sm:text-2xl">
          A sua ferramenta para gestão de cardápio digital
        </span>
        <span className="text-2xl font-medium text-neutral-n4 max-sm:text-base">
          Faça agora o seu cadastro para ter acesso ao cardápio digital Mango e
          todas as suas vantagens
        </span>
        <div className="flex flex-col gap-3 text-start">
          <div className="flex items-center gap-3">
            <Icon color="neutral-n6" name="pig-money" />
            <span className="flex-1 text-lg font-medium text-neutral-n6 max-sm:text-base">
              Economize com impressões
            </span>
          </div>
          <div className="flex items-center gap-3">
            <Icon color="neutral-n6" name="file-smile" />
            <span className="flex-1 text-lg font-medium text-neutral-n6 max-sm:text-base">
              Atualize seu cardápio a qualquer momento
            </span>
          </div>
          <div className="flex items-center gap-3">
            <Icon color="neutral-n6" name="mood-smile-beam" />
            <span className="flex-1 text-lg font-medium text-neutral-n6 max-sm:text-base">
              Proporcione uma experiência incrível ao seu cliente
            </span>
          </div>
          <div className="flex items-center gap-3">
            <Icon color="neutral-n6" name="plant" />
            <span className="flex-1 text-lg font-medium text-neutral-n6 max-sm:text-base">
              Contribua com o meio ambiente
            </span>
          </div>
        </div>
        <span className="text-lg font-medium text-neutral-n6 max-sm:text-base">
          Conheça essas e muitas outras vantagens com o Mango.
        </span>
        <div className="flex items-center gap-4 max-lg:flex-col">
          <Button
            id="button-know-advantages"
            type="button"
            text="Conheça as vantagens"
            className="max-sm:w-full"
            onClick={() => scrollTo('advantages')}
          />
          <RegisterButton
            id="button-register-06"
            className="class-id-button-register"
          />
        </div>
        <Card
          iconColor="primary-p-0"
          iconName="star-filled"
          iconsQuantity={5}
          title="Acclamé"
          subtitle="“O Mango é essencial ao meu negócio!”"
          className="max-w-[481px] shadow-lg"
          classNameTitle={cx(
            'text-primary-p-0 text-xl !font-bold max-sm:text-base'
          )}
          classNameSubtitle={cx(
            'text-primary-p-d90 text-xl !font-bold max-sm:text-base'
          )}
        ></Card>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center max-md:order-1">
        <Image
          src="https://res.cloudinary.com/ds7oehzox/image/upload/landingpage/ulytl4tpqghe47i31ikf.png"
          className="h-full min-h-[313px] w-full min-w-[313px]"
          alt={'Baker Cuate'}
        />
      </div>
      <GoTo
        text="Vantagens"
        goto="advantages"
        id="button-goto-03"
        className="class-id-button-goto"
      />
    </Container>
  )
}
