import type { HTMLProps } from 'react'

type AlmondsProps = HTMLProps<SVGSVGElement>

export function Almonds({
  color = '#000',
  width = 24,
  height = 24,
  size = 0,
  ...otherProps
}: AlmondsProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill="none"
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect width="96" height="96" fill="white" />
      <path
        d="M19.9083 32.0924C6.44958 45.5511 4.45422 65.3766 17.539 78.4614C30.6239 91.5462 50.4493 89.5509 63.908 76.0922C77.3667 62.6335 92.0002 31.077 78.4618 17.5386C64.9234 4.00025 33.3669 18.6337 19.9083 32.0924Z"
        stroke={color}
        strokeWidth="8"
      />
      <path
        d="M20.0005 60C22.0005 34 60.0001 24 60.0001 24"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9998 76C62.0391 74.6004 72 36 72 36"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 70L39.9999 56"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52 44L60 36"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
