import { forwardRef, useState } from 'react'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cx } from 'cva'
import { format } from 'date-fns'
import { RefCallBack } from 'react-hook-form'
import { borderedInputVariants } from './bordered-input-variants'
import { Container } from './support-components/container'
import { ErrorMessage } from './support-components/error-message'
import { Label } from './support-components/label'
import { LabelText } from './support-components/label-text'
import type { BaseInputProps } from './types/base-input-props'

export type InputDatePickerProps = BaseInputProps<'input'> & {
  value?: Date
  onChange?: (value: Date | undefined) => void
  onChangeInput?: (value: Date) => void
}

export const InputDatePicker = forwardRef(
  (
    {
      className = '',
      classNameErrorMessage = 'text-left',
      classNameInput = 'cursor-pointer select-none',
      classNameLabel = '',
      errorMessage = '',
      label = '',
      value = undefined,
      onChange = () => {},
      onChangeInput = () => {},
      ...otherProps
    }: InputDatePickerProps,
    ref
  ) => {
    const currentDate = new Date()
    const initialDate = new Date(currentDate.getFullYear() - 35, 5, 15)
    const [date, setDate] = useState<Date | undefined>(initialDate)
    const [dateSelected, setDateSelected] = useState(false)
    const [open, setOpen] = useState(false)
    const moreThen18yearsOld = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDay()
    )

    const handleSelectDate = (newDate?: Date) => {
      if (newDate === undefined) {
        if (!value) newDate = initialDate
        else newDate = value
      }
      setDateSelected(true)
      setDate(newDate)
      setOpen(false)
      onChange(newDate)
      onChangeInput(newDate)
    }

    const handleOpen = (isOpen: boolean) => {
      setOpen(isOpen)
    }

    return (
      <Popover open={open} onOpenChange={handleOpen}>
        <PopoverTrigger className="outline-none">
          <Container className={className}>
            <Label>
              {!!label && (
                <LabelText className={classNameLabel} invalid={!!errorMessage}>
                  {label}
                </LabelText>
              )}

              <input
                className={cx(
                  borderedInputVariants({ invalid: !!errorMessage }),
                  classNameInput
                )}
                type="text"
                value={
                  date && dateSelected
                    ? format(date, 'dd/MM/yyyy')
                    : !!value
                    ? format(value, 'dd/MM/yyyy')
                    : ''
                }
                readOnly
                {...otherProps}
                ref={ref as RefCallBack}
              />
            </Label>

            {!!errorMessage && (
              <ErrorMessage className={classNameErrorMessage}>
                {errorMessage}
              </ErrorMessage>
            )}
          </Container>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            defaultMonth={date}
            onSelect={handleSelectDate}
            initialFocus
            disabled={(date) =>
              date >= moreThen18yearsOld || date <= new Date(1900, 0, 1)
            }
          />
        </PopoverContent>
      </Popover>
    )
  }
)
