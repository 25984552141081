export const IconMango = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24.7918C0 25.7827 0.141184 26.7407 0.404533 27.6466C0.635441 28.4409 0.960269 29.1953 1.3662 29.8969C1.81436 30.6714 2.36137 31.3816 2.98998 32.0102C3.61858 32.6388 4.32879 33.1858 5.10334 33.634C5.86704 34.0759 6.6933 34.4216 7.56557 34.6548C8.40849 34.8801 9.29439 35.0002 10.2083 35.0002H13.8542C15.4862 35.0002 17.0682 34.7771 18.5734 34.3587C20.131 33.9257 21.6065 33.2836 22.9703 32.463C24.3534 31.6307 25.6216 30.6148 26.7441 29.4474C27.8666 28.28 28.8434 26.961 29.6437 25.5226C30.4328 24.1043 31.0502 22.5698 31.4666 20.9498C31.8689 19.3844 32.0833 17.7392 32.0833 16.0418C32.0833 14.8458 31.9394 13.6834 31.6678 12.5711C31.3361 11.2125 30.8141 9.92851 30.1317 8.74898C29.4914 7.64248 28.71 6.6279 27.812 5.7299C26.914 4.83188 25.8994 4.05043 24.7929 3.41021C23.7019 2.77895 22.5215 2.28498 21.2754 1.95192C20.0712 1.63008 18.8056 1.4585 17.5 1.4585C15.9332 1.4585 14.4146 1.66439 12.9695 2.0506C11.4742 2.45026 10.0578 3.04302 8.74854 3.80053C7.42074 4.56879 6.20324 5.50652 5.12563 6.58413C4.04802 7.66174 3.1103 8.87923 2.34203 10.207C1.58453 11.5162 0.991768 12.9327 0.592105 14.428C0.205896 15.873 0 17.3917 0 18.9585V24.7918Z"
        fill="#F99975"
      />
      <path
        d="M0 24.7918C0 25.7827 0.141184 26.7407 0.404533 27.6466C0.635441 28.4409 0.960269 29.1953 1.3662 29.8969C1.81436 30.6714 2.36137 31.3816 2.98998 32.0102C3.61858 32.6388 4.32879 33.1858 5.10334 33.634C5.86704 34.0759 6.6933 34.4216 7.56557 34.6548C8.40849 34.8801 9.29439 35.0002 10.2083 35.0002H13.8542C15.4862 35.0002 17.0682 34.7771 18.5734 34.3587C20.131 33.9257 21.6065 33.2836 22.9703 32.463C24.3534 31.6307 25.6216 30.6148 26.7441 29.4474C27.8666 28.28 28.8434 26.961 29.6437 25.5226C30.4328 24.1043 31.0502 22.5698 31.4666 20.9498C31.8689 19.3844 32.0833 17.7392 32.0833 16.0418C32.0833 14.8458 31.9394 13.6834 31.6678 12.5711C31.3361 11.2125 30.8141 9.92851 30.1317 8.74898C29.4914 7.64248 28.71 6.6279 27.812 5.7299C26.914 4.83188 25.8994 4.05043 24.7929 3.41021C23.7019 2.77895 22.5215 2.28498 21.2754 1.95192C20.0712 1.63008 18.8056 1.4585 17.5 1.4585C15.9332 1.4585 14.4146 1.66439 12.9695 2.0506C11.4742 2.45026 10.0578 3.04302 8.74854 3.80053C7.42074 4.56879 6.20324 5.50652 5.12563 6.58413C4.04802 7.66174 3.1103 8.87923 2.34203 10.207C1.58453 11.5162 0.991768 12.9327 0.592105 14.428C0.205896 15.873 0 17.3917 0 18.9585V24.7918Z"
        fill="white"
      />
      <path
        d="M28.4375 5.10417C28.8945 5.10417 29.3374 5.04411 29.7589 4.93147C30.195 4.8149 30.6082 4.64201 30.99 4.42107C31.3773 4.197 31.7324 3.92349 32.0467 3.60919C32.361 3.29489 32.6345 2.93978 32.8586 2.55251C33.0795 2.17066 33.2524 1.75752 33.369 1.32138C33.4816 0.899923 33.5417 0.456975 33.5417 0C33.0847 0 32.6417 0.0600529 32.2203 0.172697C31.7841 0.289266 31.371 0.462154 30.9892 0.683092C30.6019 0.90717 30.2468 1.18067 29.9325 1.49498C29.6182 1.80928 29.3447 2.16438 29.1206 2.55166C28.8997 2.93351 28.7268 3.34664 28.6102 3.78278C28.4976 4.20424 28.4375 4.64719 28.4375 5.10417Z"
        fill="white"
      />
    </svg>
  )
}
