import type { HTMLProps } from 'react'

type DyesProps = HTMLProps<SVGSVGElement>

export function Dyes({
  color = '#000',
  width = 24,
  height = 24,
  size = 0,
  ...otherProps
}: DyesProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M40.9779 15.7341L23.8484 41.8966C16.9119 53.8392 19.5765 68.8016 30.0356 77.508C40.4316 86.164 55.5477 86.164 65.9403 77.508C76.3994 68.8016 79.064 53.8356 72.2255 42.0624L55.0015 15.7341C51.7194 10.7048 44.2106 10.8061 40.9779 15.7341Z"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
