import type { HTMLProps } from 'react'

type GlutenProps = HTMLProps<SVGSVGElement>

export function Gluten({
  color = '#000',
  width = 24,
  height = 24,
  size = 0,
  ...otherProps
}: GlutenProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={color}
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M23.7991 40.2843C25.3612 41.8464 25.3612 44.3791 23.7991 45.9412C15.9886 53.7516 15.9886 66.4149 23.7991 74.2254C31.6096 82.0359 44.2729 82.0359 52.0834 74.2254C53.6455 72.6633 56.1782 72.6633 57.7403 74.2254C59.3024 75.7875 59.3024 78.3202 57.7403 79.8823C46.8056 90.817 29.077 90.817 18.1423 79.8823C7.2076 68.9476 7.2076 51.219 18.1423 40.2843C19.7044 38.7222 22.237 38.7222 23.7991 40.2843Z" />
      <path d="M52.0833 45.9412C53.6454 47.5033 53.6454 50.0359 52.0833 51.598L18.1422 85.5391C16.5801 87.1012 14.0474 87.1012 12.4853 85.5391C10.9232 83.9771 10.9232 81.4444 12.4853 79.8823L46.4265 45.9412C47.9886 44.3791 50.5212 44.3791 52.0833 45.9412Z" />
      <path d="M52.0835 79.8822C44.2731 72.0718 31.6098 72.0718 23.7993 79.8822C22.2372 81.4443 19.7044 81.4444 18.1423 79.8823C16.5802 78.3202 16.5803 75.7875 18.1424 74.2254C29.0771 63.2907 46.8056 63.2908 57.7403 74.2254C59.3024 75.7875 59.3024 78.3202 57.7403 79.8823C56.1782 81.4444 53.6456 81.4443 52.0835 79.8822Z" />
      <path d="M18.1424 45.9411C25.9529 53.7516 25.9529 66.4149 18.1424 74.2254C16.5803 75.7875 16.5802 78.3202 18.1423 79.8823C19.7044 81.4444 22.2372 81.4443 23.7993 79.8822C34.734 68.9476 34.7338 51.219 23.7991 40.2843C22.237 38.7222 19.7044 38.7222 18.1423 40.2843C16.5802 41.8464 16.5803 44.379 18.1424 45.9411Z" />
      <path d="M37.9412 26.1422C39.5033 27.7043 39.5033 30.2369 37.9412 31.799C30.1307 39.6095 30.1307 52.2728 37.9412 60.0833C45.7517 67.8938 58.415 67.8938 66.2255 60.0833C67.7876 58.5212 70.3203 58.5212 71.8823 60.0833C73.4444 61.6454 73.4444 64.178 71.8823 65.7401C60.9477 76.6748 43.219 76.6748 32.2844 65.7401C21.3497 54.8055 21.3497 37.0768 32.2844 26.1422C33.8465 24.5801 36.3791 24.5801 37.9412 26.1422Z" />
      <path d="M66.2256 65.7402C58.4151 57.9297 45.7519 57.9297 37.9414 65.7402C36.3793 67.3023 33.8465 67.3022 32.2844 65.7401C30.7223 64.178 30.7224 61.6454 32.2845 60.0833C43.2192 49.1486 60.9477 49.1486 71.8823 60.0833C73.4444 61.6454 73.4444 64.178 71.8823 65.7401C70.3203 67.3022 67.7877 67.3023 66.2256 65.7402Z" />
      <path d="M52.0833 12C53.6454 13.5621 53.6454 16.0948 52.0833 17.6569C44.2728 25.4673 44.2728 38.1307 52.0833 45.9412C59.8938 53.7517 72.5571 53.7516 80.3676 45.9411C81.9297 44.379 84.4623 44.379 86.0244 45.9411C87.5865 47.5032 87.5865 50.0359 86.0244 51.598C75.0898 62.5327 57.3611 62.5327 46.4265 51.598C35.4918 40.6633 35.4918 22.9347 46.4265 12C47.9886 10.4379 50.5212 10.4379 52.0833 12Z" />
      <path d="M80.9471 13.6991C83.133 14.0192 84.6454 16.0507 84.3253 18.2365L80.3675 17.6569C84.3253 18.2365 84.3251 18.2377 84.325 18.2389L84.3246 18.2417L84.3236 18.2481L84.3211 18.2646L84.3138 18.312C84.3079 18.35 84.2998 18.4007 84.2894 18.4634C84.2685 18.589 84.2383 18.7632 84.1973 18.9818C84.1154 19.4188 83.9902 20.0345 83.8105 20.7948C83.4516 22.3125 82.8718 24.4212 81.9776 26.8419C80.2025 31.6472 77.1164 37.8786 71.8823 43.1127C70.3202 44.6748 67.7875 44.6748 66.2254 43.1127C64.6633 41.5506 64.6633 39.018 66.2254 37.4559C70.3638 33.3174 72.9346 28.2351 74.4732 24.0698C75.2359 22.0052 75.7271 20.2144 76.0251 18.954C76.1738 18.3252 76.2735 17.8321 76.3343 17.5076C76.3647 17.3455 76.3854 17.2258 76.3975 17.1526C76.4036 17.116 76.4075 17.0911 76.4095 17.0784L76.4103 17.0733C76.4105 17.0719 76.4108 17.0705 76.411 17.0691C76.7349 14.8877 78.764 13.3794 80.9471 13.6991Z" />
      <path d="M84.3253 17.0773C84.0052 14.8915 81.9738 13.379 79.7879 13.6991L80.3675 17.6569C79.7879 13.6991 79.7867 13.6993 79.7855 13.6995L79.7828 13.6999L79.7763 13.7008L79.7599 13.7033L79.7124 13.7106C79.6744 13.7165 79.6238 13.7246 79.561 13.735C79.4354 13.7559 79.2612 13.7861 79.0426 13.8271C78.6056 13.909 77.9899 14.0342 77.2297 14.2139C75.7119 14.5728 73.6032 15.1526 71.1825 16.0468C66.3772 17.8219 60.1459 20.908 54.9117 26.1422C53.3496 27.7043 53.3496 30.2369 54.9117 31.799C56.4738 33.3611 59.0065 33.3611 60.5686 31.799C64.707 27.6606 69.7894 25.0898 73.9546 23.5512C76.0192 22.7885 77.81 22.2973 79.0704 21.9993C79.6993 21.8506 80.1923 21.7509 80.5168 21.6901C80.6789 21.6597 80.7986 21.6391 80.8718 21.6269C80.9084 21.6208 80.9333 21.6169 80.9461 21.6149L80.9511 21.6141C80.9525 21.6139 80.9539 21.6137 80.9553 21.6135C83.1367 21.2895 84.645 19.2604 84.3253 17.0773Z" />
      <path d="M80.3677 51.598C72.5572 43.7875 59.8938 43.7875 52.0833 51.598C50.5212 53.1601 47.9886 53.1601 46.4265 51.598C44.8644 50.0359 44.8644 47.5033 46.4265 45.9412C57.3611 35.0065 75.0898 35.0064 86.0244 45.9411C87.5865 47.5032 87.5865 50.0359 86.0244 51.598C84.4623 53.1601 81.9298 53.1601 80.3677 51.598Z" />
      <path d="M32.2845 31.799C40.095 39.6095 40.095 52.2728 32.2845 60.0833C30.7224 61.6454 30.7223 64.178 32.2844 65.7401C33.8465 67.3022 36.3793 67.3023 37.9414 65.7402C48.876 54.8055 48.8759 37.0768 37.9412 26.1422C36.3791 24.5801 33.8465 24.5801 32.2844 26.1422C30.7223 27.7043 30.7224 30.2369 32.2845 31.799Z" />
      <path d="M46.4266 17.6569C54.2371 25.4674 54.2369 38.1307 46.4265 45.9412C44.8644 47.5033 44.8644 50.0359 46.4265 51.598C47.9886 53.1601 50.5212 53.1601 52.0833 51.598C63.018 40.6633 63.018 22.9347 52.0833 12C50.5212 10.4379 47.9886 10.4379 46.4265 12C44.8644 13.5621 44.8645 16.0948 46.4266 17.6569Z" />
    </svg>
  )
}
