import { type ComponentPropsWithoutRef } from 'react'

type ImageProps = ComponentPropsWithoutRef<'img'> & {
  src: string
  alt: string
}

export function Image({
  src,
  alt,
  // width = 200,
  // height = 200,
  ...otherProps
}: ImageProps) {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center">
        <img
          src={src}
          // width={width}
          // height={height}
          alt={alt}
          {...otherProps}
        />
      </div>
    </div>
  )
}
