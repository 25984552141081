import { type ComponentPropsWithRef } from 'react'
import { cx } from 'cva'
import { Image } from '@components/ui/img'
import { cn } from '@utils/styles'
import { Color } from '@config/colors'
import { Icon, IconName } from './icon'

type CardProps = ComponentPropsWithRef<'div'> & {
  title?: string
  subtitle?: string
  classNameTitle?: string
  classNameSubtitle?: string
  iconNameLeft?: IconName
  iconColorLeft?: Color
  iconName?: IconName
  iconColor?: Color
  iconsQuantity?: number
  figureUrl?: string
}

export const Card = ({
  className = '',
  title = '',
  subtitle = '',
  classNameTitle = '',
  classNameSubtitle = '',
  iconNameLeft,
  iconColorLeft,
  iconName,
  iconColor,
  iconsQuantity = 1,
  children = null,
  figureUrl = '',
  ...otherProps
}: CardProps) => {
  return (
    <div
      className={cn('rounded-2xl bg-neutral-n12 p-6 shadow-card', className)}
      {...otherProps}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            {!!figureUrl && (
              <figure className="overflow-hidden rounded-full">
                <Image
                  src={figureUrl}
                  alt="logo do restaurante"
                  width={64}
                  height={64}
                />
              </figure>
            )}

            {!!iconNameLeft && (
              <Icon name={iconNameLeft} color={iconColorLeft} />
            )}

            {!!title && (
              <h1
                className={cx(
                  'text-2xl font-medium text-neutral-n4',
                  classNameTitle
                )}
              >
                {title}
              </h1>
            )}
          </div>
          <div className="flex gap-2">
            {!!iconName &&
              [...Array(iconsQuantity)].map((value: undefined, key: number) => (
                <Icon key={key} name={iconName} color={iconColor} />
              ))}
          </div>
        </div>
        {!!subtitle && (
          <h2
            className={cx(
              'text-base font-medium text-neutral-n8',
              classNameSubtitle
            )}
          >
            {subtitle}
          </h2>
        )}
      </div>

      {children}
    </div>
  )
}
