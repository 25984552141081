import { ChangeEvent, forwardRef } from 'react'
import { useMask } from '@react-input/mask'
import { cx } from 'cva'
import { borderedInputVariants } from './bordered-input-variants'
import { Container } from './support-components/container'
import { ErrorMessage } from './support-components/error-message'
import { Label } from './support-components/label'
import { LabelText } from './support-components/label-text'
import type { BaseInputProps } from './types/base-input-props'

export type InputPhoneCellPhoneProps = BaseInputProps<'input'> & {
  value?: string
  /** Runs a function over `value` before calling `onChange` */
  normalize?: (value: string, previousValue: string) => string
  onChange?: (value: string) => void
}

export const InputPhoneCellPhone = forwardRef(
  (
    {
      className = '',
      classNameErrorMessage = '',
      classNameInput = '',
      classNameLabel = '',
      errorMessage = '',
      label = '',
      value = '',
      normalize = (value) => value,
      onChange = () => {},
      ...otherProps
    }: InputPhoneCellPhoneProps,
    ref
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const normalizedValue = normalize(e.target.value, value)
      onChange(normalizedValue)
    }

    const modify = (input: string) => {
      const lenght = input?.length || 0
      return { mask: lenght <= 10 ? '(__) ____ _____' : '(__) _ ____ ____' }
    }

    const inputRef = useMask({
      mask: '(__) _ ____ ____',
      replacement: { _: /\d/ },
      modify,
    })

    return (
      <Container className={className}>
        <Label>
          {!!label && (
            <LabelText className={classNameLabel} invalid={!!errorMessage}>
              {label}
            </LabelText>
          )}

          <input
            className={cx(
              borderedInputVariants({ invalid: !!errorMessage }),
              classNameInput
            )}
            type="text"
            value={value}
            onChange={handleChange}
            {...otherProps}
            ref={inputRef}
          />
        </Label>

        {!!errorMessage && (
          <ErrorMessage className={classNameErrorMessage}>
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    )
  }
)
